import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Markup } from 'interweave';
import { Button, Card } from 'react-bootstrap';

const BannerImage = ({ categorydetails }) => {
    
    return (
        <>
            <div className='banner_image'>
                <img
                    className="d-block w-100 IRA-Realty-carousel1"
                    src={categorydetails.bg_image} alt=""
                />
                <div className="overlay">
                    <h1 className="card-title">{categorydetails.category_name}</h1>
                    <div className="card-text slideshow-card-description">
                         <Markup className='' content={categorydetails.description} />
                    </div>
                </div>
            </div>

            <section>
                <Container className='categorydetail'>
                    <Card>
                        <Card.Body>
                            <Row className='categorydetail_row'>
                                {categorydetails.key_points.map((Element, index) => {
                                    return (
                                        <Col lg={4} key={index}>
                                            <Row>
                                                <Col lg={4}>
                                                    <img src={Element.icon} alt="" />
                                                </Col>
                                                <Col lg={8} className="pt-3">
                                                    {Element.message}
                                                </Col>
                                            </Row>
                                        </Col>
                                    )

                                })
                                }
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </section>

        </>
    );
};

export default BannerImage;