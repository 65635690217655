import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import {Container, Row, Col} from 'react-bootstrap';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from '../../components/ErrorFallback';
import Form from 'react-bootstrap/Form';
import BlogHeader from '../../components/BlogHeader';
import {AiOutlineArrowRight} from 'react-icons/ai';
import {NavLink} from 'react-router-dom';
import {MdOutlineFilterAlt} from 'react-icons/md';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import DynamicComponentWithScript from '../../components/DynamicComponentWithScript';

const Blog = () => {
  const [blogData, setBlogData] = useState([]);
  const [blog_list_Data, setBlogListData] = useState([]);
  const [filteBlogData, setFilterBlogData] = useState([]);
  const [filterButton, setFilterButton] = useState(false);
  const [buttonStyle, setButtonStyle] = useState(false)
  const [checked, setChecked] = useState(false)
  const [isCheck, setIsCheck] = useState([]);

  const api_call = useRef(true);

  const getBlogSections = () => {
    axios
      //.get('https://www.irarealty.in/getBlogList')
      .get('api/getBlogList')
      .then((response) => {
        setBlogData(response.data);
        setBlogListData(response.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  useEffect(() => {
    if (api_call.current) {
      api_call.current = false;
      getBlogSections();
    }
  }, []);

  const handelFilterClick = () => {
    setFilterButton(!filterButton);
  };

  const checkBoxList = (event) => {
    const {id, checked} = event.target;
    {isCheck.includes(id) ?  isCheck.pop(id) : setIsCheck([...isCheck, id])}
    // setIsCheck([...isCheck, id])
    console.log(event.target.checked, "event.target.checked")
    console.log(event.target.value, "event.target.value")
    if(event.target.value !== ""){
      setButtonStyle(true)
    }

    if (checked) {
      const filterData = blogData.filter(
        (each) => each.cat_name === event.target.value
      );
      setFilterBlogData([...filteBlogData, ...filterData]);
    } else {
      setFilterBlogData(
        filteBlogData.filter(
          (each) => each.cat_name !== event.target.value
        )
      );
    }
  };
  
  useEffect(() => {
    setBlogListData(
      filteBlogData.length === 0 ? blogData : filteBlogData
    );
  }, [filteBlogData]);

  
  const header = {
    title: 'BLOGS',
    descriptison:
      'Curated articles of our thoughts and experiences on Real Estate and more',
  };

  const dataBot = "28955060";

  return (
    <div>
      {/* <DynamicComponentWithScript
        slug=""
        databot={dataBot}
      /> */}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BlogHeader data={header} />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {blogData.length > 0 && (
          <Container
            style={{marginTop: '60px'}}
            data-aos="fade-up"
            data-aos-delay="200"
            className="aos-init aos-animate"
          >
            <Row>
              <Col
                data-aos="flip-left"
                data-aos-delay="200"
                lg={4}
                sm={12}
                className="d-flex align-items-stretch aos-init aos-animate"
              >
                <div
                  className="blog-Rectangle-10-small md-none"
                  style={{marginTop: '3%'}}
                >
                  <div className="d-flex flex-row justify-content-between">
                    <h4 className="Blog-Categories-small">Categories</h4>
                    
                    <button className='position-relative'
                      style={{border: 'none', backgroundColor: 'transparent'}}
                      onClick={() => handelFilterClick()}
                    >
                    {buttonStyle && 
                    <span className="position-absolute translate-middle 
                    p-2 bg-danger border border-light rounded-circle" 
                    style={{top:'9px', width: '6px', height: '6px'}}></span>
                    
                    }
                      <MdOutlineFilterAlt className="blog-filter-icon" />
                      
                    </button>
                    
                  </div>
                  <div>
                    {filterButton && (
                      <form>
                        {blogData.filter((v, i, a) => a.findIndex(v2 => ['cat_id'].every(k => v2[k] === v[k])) === i).map((category) => (
                          <Form.Group
                            key={category.cat_id}
                            className="mb-3"
                            controlId="formBasicCheckbox"
                          >
                            <Form.Check
                              type="checkbox"
                              checked={isCheck.includes(category.cat_name) ? true : false}
                              className="Bolg-cat-name"
                              id={category.cat_name}
                              value={category.cat_name}
                              label={category.cat_name}
                              onChange={(e) => checkBoxList(e)}
                            />
                          </Form.Group>
                        ))}
                      </form>
                    )}
                  </div>
                </div>
                <div className="blog-Rectangle-10 sm-none">
                  <h4 className="Blog-Categories">Categories</h4>
                  <form>
                    {blogData.filter((v, i, a) => a.findIndex(v2 => ['cat_id'].every(k => v2[k] === v[k])) === i).map((category) => (
                      <Form.Group
                        key={category.cat_id}
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          type="checkbox"
                          className="Bolg-cat-name"
                          id={category.cat_name}
                          value={category.cat_name}
                          label={category.cat_name}
                          onChange={(e) => checkBoxList(e)}
                        />
                      </Form.Group>
                    ))}
                  </form>
                </div>
              </Col>
              <Col md={8} sm={12} className="">      
              <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 960: 2}}
            >
                <Masonry gutter='5px'>
                {blog_list_Data.map((each, i) => (
                      <Col
                      lg={12} 
                        // md={4}
                        className="blogs d-flex align-items-stretch"
                        key={i}
                      >
                        <div className="blog-icon-box pb-0">
                          <NavLink to={'/blog/'+each.slug} 
                          state={{from: `api/getBlogDetail/${each.slug}`}}
                          style={{textDecoration: 'none'}} >
                          <img
                            className="blog-list-img"
                            src={each.thumbimage}
                            alt={each.cat_name}
                          />
                          <p className="card-category">{each.cat_name}</p>
                          <h4 className="blog-list-title">{each.title}</h4>
                          <p className="blog-card-description">
                            {each.small_description}
                          </p>
                          <p className="blog-card-founder-name">
                            By {each.blog_author}
                          </p>
                          
                          
                          
                          <button 
                          type='button' 
                          className="blog-read_link" 
                          >Read More <AiOutlineArrowRight /></button>
                            
                          </NavLink>
                        </div>
                      </Col>
                      
                    ))}
                </Masonry>
            </ResponsiveMasonry>
            </Col>
            </Row>
          </Container>
        )}
      </ErrorBoundary>
    </div>
  );
};

export default Blog;