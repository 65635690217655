import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive';
import SpecificationsDesktop from './SpecificationsDesktop';
import SpecificationsMobile from './SpecificationsMobile';

function Specifications({ section6 }) {
    const LaptopOrMobile = useMediaQuery({ query: `(max-width: 575px)` });

    return (
        <>
            {LaptopOrMobile ? <SpecificationsMobile section6={section6} /> : <SpecificationsDesktop section6={section6} />}
        </>
    )
}


export default Specifications

