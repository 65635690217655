import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Markup } from 'interweave';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

function SpecificationsMobile({ section6 }) {

    return (
        <Container className='section_margin'>
            <Col className="mt-5">
                <h1 className='welcome_header project_highlights_title' style={{ textAlign: "center" }}>
                    {section6.title}
                </h1>
            </Col>
            <Row>
                {section6.section_card !== undefined && section6.section_card
                    .map((element, index) => {
                        //console.log(element.description, "specificatioin");
                        return (
                            <Col lg={4} md={5} sm={6} key={index} className="specifications_row  ">
                                <Accordion flush>
                                    <Accordion.Item eventKey={element.title}>
                                        <Accordion.Header className="specifications_title">
                                            <img className="icon-box specifications_icon" src={element.icon_url} />
                                            <p className="title_box">{element.title}</p>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Markup className="welcome_decription specifications_description" content={element.description} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        )
                    })
                }
            </Row>
        </Container>
    )
}
export default SpecificationsMobile;

