import React from 'react';
import { Navbar, Nav, Container, Button, Form, NavDropdown } from 'react-bootstrap';
import EnquireNowButton from '../EnquireNowButton';
import { LinkContainer } from 'react-router-bootstrap';
import { MdTextRotationAngledown } from 'react-icons/md';
import ChanelPartnerForm from '../ChanelPartnerForm';
import { useMediaQuery } from 'react-responsive';
//import slideImg1 from '../../../public/assets/images/Layer_1.png';

const NavItems = [
    {
        id: 2,
        text: 'About Us',
        path: '/about-ira',
    },
    {
        id: 3,
        text: 'Apartments',
        path: '/project/apartments',
    },
    {
        id: 4,
        text: 'Villas',
        path: '/project/villas',
    },
    {
        id: 5,
        text: 'Townships',
        path: '/project/townships',
    },
    {
        id: 6,
        text: 'Commercial',
        path: '/project/commercial',
    },
    {
        id: 7,
        text: 'Farms',
        path: '/project/farms',
    },
    {
        id: 10,
        text: 'Careers',
        path: '/career',
    },
    {
        id: 11,
        text: 'CP Registration',
        path: '',
    },
];

const NavItems1 = [
    {
        id: 8,
        text: 'Blogs',
        path: '/blogs',
    },
    {
        id: 9,
        text: 'Media',
        path: '/media',
    },
    
];


const Header = () => {
    //change navbar color when scroll
    const [color, setColor] = React.useState(false);
    const [menu, setmenu] = React.useState(false);
    const [showchannel, setShowChannel] = React.useState(false);

    const isDesktopOrMobile = useMediaQuery({ query: `(max-width: 992px)` });

    const changeColor = () => {
        if (window.scrollY >= 680) {
            setColor(true);
        } else {
            setColor(false)
        }
    }

    const handlclick = () => {
        setmenu(!menu);
    }

    React.useEffect(() => {
        if (menu) {
            document.getElementsByClassName('navbar_bg')[0].style.opacity = '1'
        }
        else {
            document.getElementsByClassName('navbar_bg')[0].style.opacity = '0.8';
        }
    }, [menu])

    window.addEventListener('scroll', changeColor)

    const [activeIndex, setactiveIndex] = React.useState(0);

    React.useEffect(() => {
        const interval = setInterval(() => {
            (activeIndex === NavItems1.length - 1) ? setactiveIndex(0) : setactiveIndex(activeIndex + 1)

        }, 3000);
        return () => clearInterval(interval);
    }, [activeIndex]);

    const handleShow = () => setShowChannel(true);

    // console.log("setShow", showchannel)

    return (
        <>
            <Navbar

                onToggle={handlclick}

                className="navbar_bg" bg="light" id="navbarira" collapseOnSelect expand="lg"
                style={{ backgroundColor: "#FFFFFF", opacity: color ? '' : '0.8' }}
            >
                <Container>
                    <LinkContainer to="/">
                        <Navbar.Brand href="/">
                            <img alt='Ira Logo' src='/assets/images/Layer_1.png' />
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle>
                        <span></span>
                        <span></span>
                        <span></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {isDesktopOrMobile ?
                                NavItems.filter(ele => ele.id != 11).map((item, index) => {
                                    return (
                                        <LinkContainer key={index} to={item.path} >
                                            <Nav.Link>{item.text}</Nav.Link>
                                        </LinkContainer>
                                    )
                                })
                            :
                                NavItems.filter(ele => (ele.id != 11 && ele.id != 10)).map((item, index) => {
                                    return (
                                        <LinkContainer key={index} to={item.path} >
                                            <Nav.Link>{item.text}</Nav.Link>
                                        </LinkContainer>
                                    )
                                })

                            }
                            {isDesktopOrMobile &&
                                <LinkContainer to="" onClick={() => handleShow()} style={{color: '#00'}}>
                                    <Nav.Link >{NavItems.filter(ele => ele.id == 11)[0].text}</Nav.Link>
                                </LinkContainer>
                            }
                            

                            <LinkContainer to={NavItems1[activeIndex].path} style={{width:'100px'}}>
                                <Nav.Link >{NavItems1[activeIndex].text}</Nav.Link>
                            </LinkContainer>

                            <EnquireNowButton buttonName='Enquire Now' />
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {showchannel && <ChanelPartnerForm show={showchannel} setShow={setShowChannel}/>}
        </>
    );
}
export default Header
