import React,{useState, useEffect, useRef} from 'react'
import { Form, Alert, Modal } from "react-bootstrap";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import validate from '../validation/FormValidationRules'
import useFormvalidation from '../validation/Formvalidation'


const ChanelPartnerForm = ({show, setShow}) => {
	const [disabled, setDisabled] = useState(false);
	const [disableAttr, setDisableAttr] = useState(true);
	const [formValues, setFormValues] = useState({
        name: "",
        rera: "",
        email: "",
        phone: "",
        altphone: "",
    });
    const [sessionToken, setSessionToken] = useState();
    const [otpSendMsz, setOtpSendMsz] = useState();
    const [optMatchMsz, setOptMatchMsz] = useState();
    const [errmsz, seterrmsz] = useState();
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [displayNone, setdisplayNone] = useState(false);



	const finalSubmit = () => {
			axios({
				method: "post",
				url: "api/submitChannel",
				data: values,
				headers: { "Content-Type": "multipart/form-data" },
			})
			.then(function (response) {
				setShow(false)
				setFormValues(
					{
						name: "",
						rera: "",
						email: "",
						phone: "",
					}
				)

			})
			.catch(function (err) {
				console.log("error", err);
			});

	}

	const {
		values,
		errors,
		phone, 
		setPhone,
		handleChange,
		handleSubmit,
		handlePhoneChange,
		handleAltPhoneChange,

	} = useFormvalidation(finalSubmit, validate, formValues);

	

	//get otp in phone no
	const handleOtp = (e) => {
			const api_key = "9d2cdb70-e4f0-11e9-9721-0200cd936042";

			getOtpApi(phone, api_key);
	};

	const getOtpApi = (value, api_key) => {
			//console.log(value, 'getOtpApi')
			axios
			.get(`https://2factor.in/API/V1/${api_key}/SMS/+91${value}/AUTOGEN/OTP1`)
			.then((response) => {
					setSessionToken(response.data.Details);
					setOtpSendMsz("OTP send successfully!");
			})
			.catch((err) => {
					console.log("error", err);
					seterrmsz(err.response.data.Details);
					setOtpSendMsz(errmsz);
			});
	};

	//varify otp

	const handleVarifyOtp = (e) => {
        const api_key = "9d2cdb70-e4f0-11e9-9721-0200cd936042";
        //console.log(e.target.value.length, "e.target.value");
        if (e.target.value.length === 6) {
        varifyOtpApi(e.target.value, api_key, sessionToken);
        }
	};

   

	const varifyOtpApi = (otpno, api_key, sessionToken) => {
			axios
			.get(
				`https://2factor.in/API/V1/${api_key}/SMS/VERIFY/${sessionToken}/${otpno}`
			)
			.then((response) => {
					//console.log(response.data)
					
					if(response.data.Status === "Success"){
						setSubmitSuccess(true)
					}else if(response.data.Status === "Error"){
						setSubmitSuccess(false)
					}
					
					setOptMatchMsz(response.data.Details);
					setOtpSendMsz();
					setDisabled(true);
			})
			.catch((err) => {
					console.log("error", err);
					setOptMatchMsz("OTP mismatch");
			});
	};

	const handleClose = () => {
		setShow(false)
	};
	

  return (
    <Modal className="enquireform_modal" show={show}>

        <Modal.Body className="enquiryform_body">
          <div className="enquire_now_heading">CHANNEL PARTNER</div>
          <div className="footer_enquiry">
            {/* {responseSuccess === true && (
                <Alert variant="success" onClick={() => closeShow()} dismissible>
                <p>
                    <b>Message sent successfully !</b>
                </p>
                </Alert>
            )} */}
            <Form.Group className="input_padding" controlId="formBasicEmail">
                <Form.Control
                type="text"
                name="name"
                placeholder="Name"
                onChange={(e) => handleChange(e)}
                />
                <p className="error">
                {errors.hasOwnProperty("name") ? errors.name : ""}
                </p>
            </Form.Group>
            <Form.Group className="input_padding" controlId="formBasicEmail">
                <Form.Control
                type="text"
                name="rera"
                placeholder="Rera No"
                onChange={(e) => handleChange(e)}
                />
                <p className="error">
                {errors.hasOwnProperty("rera") ? errors.rera : ""}
                </p>
            </Form.Group>
            <Form.Group className="input_padding" controlId="formBasicPassword">
                <Form.Control
                type="email"
                name="email"
                placeholder="Email"
                
                onChange={(e) => handleChange(e)}
                />
                <p className="error">
                {errors.hasOwnProperty("email") ? errors.email : ""}
                </p>
            </Form.Group>
            <Form.Group className="input_padding" controlId="formBasicPassword">
                <PhoneInput
                international={false}
                placeholder="Phone Number"
                initialValueFormat="national"
                defaultCountry="IN"
                name="phone"
                /* value={value} */
                onChange={(e) => handlePhoneChange(e)}
                //error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                />
                <p className="error">
                {errors.hasOwnProperty("phone") ? errors.phone : ""}
                </p>
            </Form.Group>
            <Form.Group
                className="input_padding"
                controlId="formBasicPassword"
                style={{ marginTop: "20px" }}
            >
                <span className="otp_text text-success">{optMatchMsz}</span>
                <Form.Control
                type="text"
                name="otp"
                placeholder="OTP"
                onChange={(e) => handleVarifyOtp(e)}
                />
                <span className="otp_text" onClick={(e) => handleOtp(e)}>
                Click to get one time Password (OTP)
                </span>
                {displayNone === true && (
                <span className="otp_text" onClick={(e) => handleOtp(e)}>
                    Resend OTP
                </span>
                )}
                <br></br>
                <span className="otp_text text-danger">{errmsz}</span>
                <span className="otp_text text-success">{otpSendMsz}</span>
            </Form.Group>
            <Form.Group className="input_padding" controlId="formBasicPassword">
                <PhoneInput
                international={false}
                placeholder="Alternate Phone Number"
                initialValueFormat="national"
                defaultCountry="IN"
                //defaultValue={phoneNo}
                name="altphone"
                onChange={(e) => handleAltPhoneChange(e)}
                //error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                />
                <p className="error">
                {/* {formErrors.hasOwnProperty("value") ? formErrors.value : ""} */}
                </p>
            </Form.Group>
            <Form.Text
                id="passwordHelpBlock"
                className="form_text"
                style={{ color: "#444444" }}
            >
                I authorize IRA Realty Tech Pvt Ltd and its representatives to Call,
                SMS, Email or WhatsApp me about its products and offers. This consent
                overrides any registration for DNC / NDNC
            </Form.Text>
            <div className="enquireformbtn_group">
                {/* {colseButton ? ( */}
                <div className="cancel_div" style={{ marginRight: "4%" }}>
                    <button className="cancel_btn1" onClick={() => handleClose()}>
                    Cancel
                    </button>
                </div>
               {/*  // ) : (
                // <div style={{ marginLeft: "0%" }}></div>
                // )} */}

                <div className={"btn_margin"}>
                    <button
                        className={`${
                        disabled === true
                            ? "submit_btn1 attrAttribute"
                            : "submit_btn_disabled attrAttribute"
                        }`}
                        style={!disableAttr ? { pointerEvents: "none" } : {}}
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>
            </div>


        </Modal.Body>
      </Modal>

    
  )
}

export default ChanelPartnerForm
