import { Route, Routes, BrowserRouter, HashRouter } from 'react-router-dom'

import Home from './views/Home'
import About from './views/about/About'
import Category from './views/Category';
import ProjectDetails from './components/ProjectDetails';
import React from 'react';
import Layout from './components/Layout/Layout';
import Blog from './views/Blog/Blog'
import BlogSlugComponent from './components/BlogSlugComponent'
import Media from './views/Media/Media'
import PrivacyPolicy from './views/PrivacyPolicy/PrivacyPolicy'
import TermsConditions from './views/TermsConditions/TermsConditions'
import AOS from "aos";
import JoinRevolution from './views/JoinRevolution/JoinRevolution';
import Cancel from './views/Cancellation/Cancel';
import CareerPage from './views/Career/CareerPage';


import "aos/dist/aos.css";

function App() {


  // const [loader, setloader] = React.useState(true);

  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])



  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" exact element={<Home />} />

          <Route path="/about-ira" element={<About />} />
          <Route path="/project/apartments" element={<Category slug='apartments'/>} />
          <Route path="/project/villas" element={<Category slug='villas' />} />
          <Route path="/project/townships" element={<Category slug='townships' />} />
          <Route path="/project/commercial" element={<Category slug='commercial' />} />
          <Route path="/project/farms" element={<Category slug='farm' />} />
          <Route path="/project/:slug" element={<ProjectDetails />} />
          <Route path='/blogs' element={<Blog />} />
          <Route path='/blog/:slug' element={<BlogSlugComponent />} />
          <Route path="/media" element={<Media />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/join-the-revolution" element={<JoinRevolution />} />
          <Route path="/refund-and-cancelation-conditions" element={<Cancel />} />
          <Route path="/career" element={<CareerPage />} />
          {/* <Route path="/farm" element={<Farm />} /> */}
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}


export default App
