import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import FormComponent from './FormComponent'
import { Modal, Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

const EnquireForm = () => {
  const [footer] = useState(true)
  const dispatch = useDispatch();

  const showModal = useSelector((state) => state.showModal);

  return (
    <div>
      <Modal className="enquireform_modal" show={showModal}>
        <Modal.Body className="enquiryform_body">
          <div className="enquire_now_heading">ENQUIRE NOW</div>
          {/* <div className="enquire_now_heading">ENQUIRE NOW</div> */}

          <FormComponent colseButton={footer} />



        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EnquireForm;


