import { useState, useEffect } from 'react';

const useFormvalidation = (callback, validate, formValues) => {
    
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [phone, setPhone] = useState("")


    useEffect(() => {
        setValues(formValues);
    }, [formValues]);


    useEffect(() => {

        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
        }
    }, [errors]);
    

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
       
    };

    const handleChange = (event) => {
       setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    };
    //console.log("setValues", values)

    const  handlePhoneChange = (e) => {
        setPhone(e)
        setValues(values => ({ ...values, ["phone"]: e}));
    }

    const  handleAltPhoneChange = (e) => {
        setValues(values => ({ ...values, ["altphone"]: e}));
    }


	

    return {
        handleChange,
        handleSubmit,
        handlePhoneChange,
        handleAltPhoneChange,
        values,
        errors,
        phone, 
        setPhone
    }
};

export default useFormvalidation;