import React, {useEffect, useRef,useState} from 'react'
import axios from 'axios';
import parse from 'html-react-parser'
import BlogHeader from '../../components/BlogHeader';
import { useNavigate } from 'react-router-dom';

import { Markup } from 'interweave';


const CareerPage = () => {

	const api_call = useRef(true);

	const navigate = useNavigate();
	const [careerdata, SetCareerdata] = useState([])

	const getCareerSections = () => {
    axios
      .get('http://localhost/ira_cms/api/getCareerList')
      .then((response) => {
		
        SetCareerdata(response.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

	const sendEmail = (e, data, sub) => {
		
    	document.location.href = `mailto: ${data}?subject=Application for ${sub}`;  
  	}

	useEffect(() => {
    if (api_call.current) {
      api_call.current = false;
      getCareerSections();
    }
  }, []);


  return (
    <>
			<BlogHeader data={careerdata.banner} />
			<section className="contact" id="jobs">
				<div className="container mb-5 py-5">
					 
				{(careerdata.result != undefined && careerdata.result.length > 0) && careerdata.result.map((each,i) => (
					<React.Fragment key={i}>
						{/* <div>{each.body}</div> */}
						<div className="row">
								<div className="col-lg-6 text-start">
								<h2>{each.title}</h2>
								</div>
								<div className="col-lg-6 text-start" style={{textAlign: 'right!important'}}> 
									<button type="submit" className="h2 fw-bold btn btn-primary btn-one px-5 py-2 border-0" style={{backgroundColor: 'rgb(54, 42, 130)', }} onClick={(e) => sendEmail(e.target.value, each.author, each.title)}  /* onClick={sendEmail(each.author)} */ > Apply</button>
								</div>
						</div>
						<div className="row">
								<div className="col-lg-12 text-start">
								<br />
								<Markup content={each.body} />
								</div>
						</div>
					</React.Fragment>
				))}
				</div>
			</section>
		</>
  )
}

export default CareerPage
