import React, {useState, useEffect, useRef} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from '../components/ErrorFallback';
import {Container, Row, Col} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import HTMLReactParser, { domToReact } from 'html-react-parser';
// import ReactHtmlParser from 'react-html-parser'
import {useLocation} from 'react-router-dom';
import axios from 'axios';

const BlogSlugComponent = () => {
  const api_call = useRef(true);
  const [slugData, setSlugData] = useState({});
  const location = useLocation();
  const {state} = location;

  console.log("state", state)

  const getBlogSlog = () => {

    axios
      .get(state.from)
      .then((response) => {
        console.log("getBlogSlog", response)
        setSlugData(response.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  useEffect(() => {
    if (api_call.current) {
      api_call.current = false;
      getBlogSlog();
    }
  }, []);


  function decodeHtml(html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  const html =
    Object.keys(slugData).length > 0 && decodeHtml(slugData.result.body);
  
  

  return (
    <div>
      {Object.keys(slugData).length > 0 && (
        <>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
              <div id="parent2">
                <img
                  className="blog_background_img"
                  src={slugData.banner.img_url}
                  alt="blogs-background-img"
                />
                <div id="child2">
                  <Container>
                    <h1 className="Blogs-Detail">{slugData.result.title}</h1>
                    
                  </Container>
                </div>
              </div>
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Container>
              <Row>
                <Col>
                <div className='mt-5'></div>
                 {html.length > 0 && HTMLReactParser(html, {
                    replace: (domNode) => {
                      
                     
                      if (domNode.name === 'p') {
                       
                       
                        
                      }
                      if (domNode.name === 'a') {
                        
                        //console.log(domNode, 'a');
                        
                       
                      }
                      
                      if (domNode.name === 'img') {
                       
                        //console.log(domNode, 'images');
                        
                      }
                      if (domNode.data === '\n') {
                        
                        //console.log(domNode, 'line');
                        
                      }
                      if (domNode.data === '\n\n') {
                        
                        //console.log(domNode, 'next');
                        
                      }
                      if (domNode.name === 'span') {
                       
                        //console.log(domNode, 'span');
                        
                      }
                      if (domNode.name === 'em') {
                      
                        //console.log(domNode, 'span');
                        return <div>{domNode.childNodes[0].data}</div>
                        
                      }
                      
                  } 
                 })}
                </Col>
              </Row>
            </Container>
          </ErrorBoundary>
        </>
      )}
    </div>
  );
};

export default BlogSlugComponent;
