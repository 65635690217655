import React from 'react';
import { Markup } from 'interweave';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const Glimpse = ({ section2 }) => {
    return (
        <Container className="section_margin glimpse_section">
            <Row>
                <Col sm={12} md={12} lg={6} className="glimpse_text">
                    <div className='glimpse_textDiv'>
                        <h3 className='A-Glimpse-Into-IRA header'>
                            {section2.title}
                        </h3>
                        <div className='para_welcome'>
                            <Markup className="welcome_decription" content={section2.description} />
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={12} lg={6} className="glimpse_img">
                    <div>
                        <div className="About-background img-fluid" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"
                            style={{ backgroundImage: `url(${section2.image_url})`, objectFit: 'cover', height:"500px" }}
                             >
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};


export default Glimpse;