import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Markup } from 'interweave';
import { useLocation } from 'react-router-dom';

const ProjectHighlights = ({ section2 }) => {
    const location = useLocation();
    const splitlocation = location.pathname.split('/');
    const slug = splitlocation[splitlocation.length -1];
    return (
        <section className='section_margin'>
            <Container className='project_highlights_main'>
                <h2 style={{ textAlign: "center" }} className='welcome_header project_highlights_title'>{section2.title}</h2>
                <p className='rera_no'>RERA No: {section2.rera_no}</p>
                
                <p className='btn-get-started'>{section2.price}</p>
                {(slug == 'aspiration' || slug == 'ira-the-square') &&
                <div class="text-center mt-3">
                    <button type="button" className="btn btn-primary book_now" style={{backgroundColor: 'rgb(54, 42, 130)', border: 'none'}}>
                        {slug == 'aspiration' &&
                            <a href='https://aspiration-booking-showcase-lite.azurewebsites.net/site-map-booking' target="_blank" rel='noreferrer'>Book Now</a>
                        }
                        {slug == 'ira-the-square' &&
                            <a href='https://square-villa-booking-showcase-lite.azurewebsites.net/site-map-booking' target="_blank" rel='noreferrer'>Book Now</a>
                        }
                    </button>
                </div>
                }
                <div className="projectdetails_description description2">
                    <Markup content={section2.description} />
                </div>
                <Row>
                    {
                        section2.section_card.map((Element, index) => {
                            return (
                                <Col lg={3} md={4} sm={6} key={index}>
                                    <div className='projecticon-box'>
                                        <div className='projecticon'>
                                            <img src={Element.icon_url} />
                                        </div>
                                        <p style={{ textAlign: "center" }} className='project_highlights_mobile projectdetails_description'>
                                            {Element.title}
                                            <br></br>
                                            <Markup className="description3" content={Element.description} />
                                        </p>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </section>
    );
};

export default ProjectHighlights;