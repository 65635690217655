import Carousel from 'react-bootstrap/Carousel';
import {Card, NavLink} from 'react-bootstrap';
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import React from 'react';
import EnquireNowButton from './EnquireNowButton';
import {Markup} from 'interweave';

const HandleProjectDataMobile = ({ projectData }) => {
    
    const [activeIndex, setactiveIndex] = React.useState(0);

    React.useEffect(() => {
        setactiveIndex(0);
    }, [projectData]);

    return (
        <Carousel key={activeIndex} activeIndex={activeIndex} controls={false} indicators={false}>
            {
                projectData.projects != undefined && projectData.projects.map((Element, index) => {
                    return (
                        
                            <Carousel.Item key={index}>
                                <NavLink href={`/project/${Element.slug}`} style={{ textDecoration: 'none', color: '#303030' }}>
                                    <Card className="featured-project-mobile-card" style={{borderRadius: '8px', border:'none'}}>
                                        <div>
                                            <Card.Text className='Rectangle-24'>
                                                {projectData.title}
                                            </Card.Text>
                                            <img src={Element.image_url} width="100%" style={{borderRadius: '8px 8px 0px 0px'}}/>
                                        </div>
                                        <Card.Body className='Featured-Projects-card2-background' style={{borderRadius: '0px 0px 8px 8px'}}>
                                            <h3 className='featured-projects-title'>
                                                {Element.title}
                                            </h3>

                                            <p>
                                                {Element.location}
                                            </p>
                                            <p>
                                                {Element.smalldescription}
                                            </p>
                                            <div style={{paddingBottom: '6%'}}>
                                                <Markup className='' content={Element.description} />
                                            </div>

                                            {Element.enable_enq_button === 'yes' &&
                                                <div style={{textAlign:'center'}}><EnquireNowButton buttonName="Enquire Now" /></div>

                                            }
                                        </Card.Body>
                                    </Card>
                                </NavLink>
                                <div className='featured-projects-mobile-view'>
                                    <button 
                                        style={activeIndex === 0 ?
                                            { color: 'gray' , Events: 'none',background:'transparent', border:'none'} : {background:'transparent', border:'none' }}

                                        className='prev' onClick={() => setactiveIndex(activeIndex => (activeIndex === 0) ? 0 : activeIndex - 1)}>
                                        <BsArrowLeftShort className='arrowleft' />Prev
                                    </button>
                                    <button

                                        style={activeIndex === projectData.projects.length - 1 ?
                                            { color: 'gray' , pointerEvents: 'none',background:'transparent', border:'none'} : { background:'transparent', border:'none'}  }

                                        className='next' onClick={() => setactiveIndex(activeIndex => (activeIndex === projectData.projects.length - 1) ? 0 : activeIndex + 1)} >
                                        Next <BsArrowRightShort className='arrowright' />
                                    </button>
                                </div>

                            </Carousel.Item>
                       
                    )
                })
            }
        </Carousel>
    );
};

export default HandleProjectDataMobile;