import React from 'react';
import { Dropdown } from 'react-bootstrap';

import useFeaturedProjecthook from './FeaturedProjecthook';

const HandleProjectListsMobile = ({ section3, GetProjectData }) => {

    const { locationData, handleItem, categoryData, projectLists, handleProjectLists } = useFeaturedProjecthook(section3);

    React.useEffect(() => {
        GetProjectData(projectLists);
    }, [projectLists])

    return (
        <div className='featured-project-mobile-dropdown'>
            <div className='featured-project-location-mobile-dropdown'>
                <Dropdown >
                    <Dropdown.Toggle className='dropbtn'>{locationData}</Dropdown.Toggle>
                    <Dropdown.Menu >
                        {
                            section3.section_card.map((Element, index) => {
                                return (
                                    <Dropdown.Item key={index} as="button" onClick={() => handleItem(Element.location_id, Element.location)}>
                                        {Element.location}
                                    </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>

            </div>
            <div className='featured-project-location-mobile-dropdown'>
                <Dropdown>
                    <Dropdown.Toggle className='dropbtn'>{projectLists.title}</Dropdown.Toggle>
                    <Dropdown.Menu >
                        {
                            categoryData.category != undefined && categoryData.category.map((Element, index) => {
                                return (
                                    <Dropdown.Item key={index} as="button" onClick={() => handleProjectLists(Element.category_id)}>
                                        {Element.title}
                                    </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
};

export default HandleProjectListsMobile;