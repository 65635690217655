export default function validate(values) {

    console.log("validate", values)
    let errors = {};
    const regexName = /^[a-zA-Z ,.'-]+$/i;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regex1 = /^(\+\d{1,3}[- ]?)?\d{10}$/;

    if (values.email === '') {
        errors.email = 'Email address is required';
    }else if (!regex.test(values.email)) {
        errors.email = "This is not a valid email format";
      }

    if (values.name === '') {
        errors.name = 'Name is required';
    }else if (!regexName.test(values.name)) {
        errors.name = "This is not a valid name format";
    }

    if (values.phone === '') {
        errors.phone = 'Phone is required';
    }else if (!regex1.test(values.phone)) {
        errors.value = "This is not a valid Number format";
      }

    if (values.rera === '') {
        errors.rera = 'Rera no is required';
    }


    return errors;
};