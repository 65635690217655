import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import HTMLReactParser from 'html-react-parser';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from '../../components/ErrorFallback';
import DynamicComponentWithScript from '../../components/DynamicComponentWithScript';

const PrivacyPolicy = () => {
  const api_call = useRef(true);
  const [privacyData, setPrivacyData] = useState([]);

  const getPrivacyPolicySections = () => {
    axios
      .get('api/Home/getPrivacyPolicy')
      .then((response) => {
        setPrivacyData(response.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  useEffect(() => {
    if (api_call.current) {
      api_call.current = false;
      getPrivacyPolicySections();
    }
  }, []);

  const dataBot = "28955060";

  const html =
    Object.keys(privacyData).length > 0 && privacyData.privacypolicy.body;

  return (
    <>
    {/* <DynamicComponentWithScript
      slug=""
      databot={dataBot}
    /> */}
    
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {privacyData.privacypolicy !== undefined && (
        <Container className="">
          <Row className="">
            <Col lg={12} className="text-left">
              <h1 className="privacy_header" style={{textAlign: 'left'}}>
                {privacyData.privacypolicy.title}
              </h1>
              {HTMLReactParser(html, {
                replace: (domNode) => {
                  console.log(domNode);
                  if (domNode.name === 'p') {
                    return (
                      <div className="privacy_decription">
                        {domNode.children[0].data}
                      </div>
                    );
                  }
                },
              })}
            </Col>
          </Row>
        </Container>
      )}
    </ErrorBoundary>
    </>
  );
};

export default PrivacyPolicy;
