import Carousel from 'react-bootstrap/Carousel';
import { useMediaQuery } from 'react-responsive';
import EnquireNowButton from './EnquireNowButton';
import { Markup } from 'interweave';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import React from 'react';
import 'aos/dist/aos.css';

const Slider = ({ section1 }) => {


  const [activeIndex, setactiveIndex] = React.useState(0);
  const LaptopOrMobile = useMediaQuery({ query: `(max-width: 567px)` });

  React.useEffect(() => {
    const interval = setInterval(() => {
      activeIndex === section1.length - 1
        ? setactiveIndex(0)
        : setactiveIndex(activeIndex + 1);
    }, 3000);
    return () => clearInterval(interval);
  }, [activeIndex]);


  return (
    <Carousel
      fade
      activeIndex={activeIndex}
      controls={false}
      indicators={false}
    >
      {section1.map((Element, index) => {
        return (
          <Carousel.Item key={index}>
            <img alt=""
              className="d-block w-100 IRA-Realty-carousel1"
              src={LaptopOrMobile ? Element.smallimg_url : Element.largeimg_url}
            />
            {Element.title !== "" &&
              <div
                className={`${'enable_enq_button' in Element ? 'overlay' : 'overlay1'
                  }`}
              >
                <h1 className="card-title">{Element.title}</h1>
              
                  <div className="card-text slideshow-card-description">
                    <Markup className="" content={Element.description} />
                  </div>
            
                
                {Element.enable_enq_button == 'yes' && (
                  <div className="slider_enquirebtn">
                    <EnquireNowButton buttonName="Enquire Now" />
                  </div>
                )}
              </div>
            }


            {section1.length > 1 && (
              <Carousel.Caption
                style={{
                  right: `${!('smalldescription' in Element) ? '-600px' : '0px'
                    }`,
                  left: `${LaptopOrMobile && '0'}`,
                  paddingBottom: `${LaptopOrMobile && '0'}`,
                }}
              >
                <div
                  className="slider_indicators"
                  style={{
                    position: `${LaptopOrMobile && 'static'}`,
                    width: `${LaptopOrMobile && '100%'}`,
                  }}
                >
                  <i
                    onClick={() =>
                      setactiveIndex((activeIndex) =>
                        activeIndex === 0 ? 0 : activeIndex - 1
                      )
                    }
                  >
                    <BsArrowLeftShort className="arrowleft" />
                  </i>
                  <span className="active_index">
                    {activeIndex + 1} {' / '} {section1.length}
                  </span>
                  <i
                    onClick={() =>
                      setactiveIndex((activeIndex) =>
                        activeIndex === section1.length - 1
                          ? 0
                          : activeIndex + 1
                      )
                    }
                  >
                    <BsArrowRightShort className="arrowright" />
                  </i>
                </div>
                {LaptopOrMobile
                  ? ''
                  : Element.smalldescription !== undefined && (
                    <div className="slider_text">
                      <p>{Element.smalldescription}</p>
                    </div>
                  )}
              </Carousel.Caption>
            )}
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default Slider;
