import React from 'react';
import BannerImage from '../components/BannerImage';
import Projects from '../components/Projects'
import axios from 'axios';
import DynamicComponentWithScript from '../components/DynamicComponentWithScript';

const Category = ({slug}) => {
    //const { slug } = useParams();

    const api_call = React.useRef(true);
    const [categorydetails, Setcategorydetails] = React.useState({});

    const getcategorydetails = () => {
        axios.get(`api/Project/getCategoryDetail/${slug}`)
            .then((response) => {
                Setcategorydetails(response.data.category_detail);
                api_call.current = true;
            })
            .catch((err) => {
                console.log("error", err);
            });
    }
    React.useEffect(() => {
        if (api_call.current) {
            api_call.current = false;
            getcategorydetails();
            Setcategorydetails({}); // making state empty because of side effects 
        }
    }, [slug])

    
    const dataBot = "28955060";

    return (
        <div>
            {/* <DynamicComponentWithScript
            slug=""
            databot={dataBot}
            /> */}
            {categorydetails.key_points !== undefined && <BannerImage categorydetails={categorydetails} />}
            {categorydetails.section_card !== undefined && <Projects ProjectsDetails={categorydetails} slug={slug}/>}
        </div>
    );
};

export default Category;
