import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Form, Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { createSerializableStateInvariantMiddleware } from "@reduxjs/toolkit";

const FormComponent = ({ colseButton }) => {
  const api_call = useRef(true);
  const [sessionToken, setSessionToken] = useState();
  const [name, setName] = useState();
  // const [phoneNo, setPhoneNO] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [otpValue, setOtpValue] = useState();
  const [otpSendMsz, setOtpSendMsz] = useState();
  const [optMatchMsz, setOptMatchMsz] = useState();
  const [optMismatchMsz, setOptMismatchMsz] = useState();
  const [errmsz, seterrmsz] = useState();
  const [property, setProperty] = useState();
  const [propertyData, setPropertyData] = useState([]);
  const [responseSuccess, setResponseSuccess] = useState(false);
  const [displayNone, setdisplayNone] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disableAttr, setDisableAttr] = useState(true);
  const [errors, setErrors] = useState({});
  const submit_Api = useRef(true);

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    property: "",
  });

  const [value, setValue] = useState();

  var status = false;

  const validate = (data) => {
    const errors = {};
    const regexName = /^[a-zA-Z ,.'-]+$/i;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regex1 = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (data.name === undefined) {
      errors.name = "Name is required";
    } else if (!regexName.test(data.name)) {
      errors.name = "This is not a valid name format";
    }
    if (data.property === undefined) {
      errors.property = "Select Project is required";
    }

    if (data.email === undefined) {
      errors.email = "Email is required";
    } else if (!regex.test(data.email)) {
      errors.email = "This is not a valid email format";
    }

    if (value === undefined) {
      errors.value = "Phone Number is required";
    } else if (!regex1.test(value)) {
      errors.value = "This is not a valid Number format";
    }

    if (Object.keys(errors).length <= 0) {
      console.log("non errr");
      status = true;
    }

    console.log(errors, "validate");

    return errors;
  };

  //get otp in phone no
  const handleOtp = (e) => {
    const api_key = "9d2cdb70-e4f0-11e9-9721-0200cd936042";
    getOtpApi(value, api_key);
  };

  const getOtpApi = (value, api_key) => {
    //console.log(value, 'getOtpApi')
    axios
      .get(`https://2factor.in/API/V1/${api_key}/SMS/+91${value}/AUTOGEN/OTP1`)
      .then((response) => {
        setSessionToken(response.data.Details);
        setOtpSendMsz("OTP send successfully!");
      })
      .catch((err) => {
        console.log("error", err);
        seterrmsz(err.response.data.Details);
        setOtpSendMsz(errmsz);
      });
  };

  //submit exquiry form
  const handleSubmit = (e) => {
    let data = {
      name: name,
      email: email,
      phone: value,
      message: message,
      property: property,
    };

    console.log("data",data);
    //console.log(data, "submitEnquireForm");
    setFormErrors(validate(data));

    if (status) {
      console.log("status", status);

      submitEnquireForm(data);
    }

  };

  const submitEnquireForm = (data) => {
    //console.log( Object.entries(data), "submitEnquireForm");
    // console.log(disableAttr,'disableAttr')

    var form_data = new FormData();

    for (var key in data) {
      form_data.append(key, data[key]);
    }

    console.log("disableAttr",disableAttr);
    console.log("submitSuccess",submitSuccess);

    if (disableAttr) {
      setDisableAttr(false);
      // submitSuccess === true &&
        axios({
            method: "post",
            url: "api/submitContact",
            data: form_data,
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(function (response) {
            setResponseSuccess(true);
            setSubmitSuccess(false)
            setDisableAttr(true);
            setName("");
            setMessage("");
            setEmail("");
            setProperty("");
            setOtpValue("")
            setValue("")
            setOptMatchMsz("")
            setPropertyData("")
            status = false;

            setTimeout(()=>{
              setResponseSuccess(false);
            },2000)

            dispatch({ type: "set", showModal: false });
          })
          .catch(function (err) {
            console.log("error", err);
          });
    }
  };

  //varify otp

  const handleVarifyOtp = (e) => {
    const api_key = "9d2cdb70-e4f0-11e9-9721-0200cd936042";
    //console.log(e.target.value.length, "e.target.value");
    if (e.target.value.length === 6) {
      varifyOtpApi(e.target.value, api_key, sessionToken);
    }
  };

  const varifyOtpApi = (otpno, api_key, sessionToken) => {
    axios
      .get(
        `https://2factor.in/API/V1/${api_key}/SMS/VERIFY/${sessionToken}/${otpno}`
      )
      .then((response) => {
        //console.log(response.data)
        if(response.data.Status === "Success"){
          setSubmitSuccess(true)
        }else if(response.data.Status === "Error"){
          setSubmitSuccess(false)
        }
        setOptMismatchMsz("");
        setOptMatchMsz(response.data.Details);
        setOtpSendMsz();
        setDisabled(true);
      })
      .catch((err) => {
        console.log("error", err);
        setOptMatchMsz("")
        //setOptMatchMsz("OTP mismatch");
        setOptMismatchMsz("OTP mismatch");
      });
  };

  //get input value
  const handleSelect = (e) => {
    setProperty(e.target.value);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const closeShow = (e) => {
    setSubmitSuccess(false);
  };

  const getValue = (e) => {
    setValue(e.target.value);
  };

  //end input value

  //for select dropdown
  const getProperty = () => {
    axios
      .get(`api/getprojectname`)
      .then((response) => {
        setPropertyData(response.data.project);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    if (api_call.current) {
      api_call.current = false;
      getProperty();
    }
  });

  const dispatch = useDispatch();

  const showModal = useSelector((state) => state.showModal);

  const handleClose = () => {
    dispatch({ type: "set", showModal: false });
  };

  return (
    <div className="footer_enquiry">
      {responseSuccess === true && (
        <Alert variant="success" onClick={() => closeShow()} dismissible>
          <p>
            <b>Message sent successfully !</b>
          </p>
        </Alert>
      )}
      <Form.Group className="input_padding" controlId="formBasicEmail">
        <Form.Select
          aria-label="Default select example"
          className="enquire_input_box form-control"
          defaultValue={property}
          name={property}
          onChange={(e) => handleSelect(e)}
        >
          <option value="">Select Project</option>
          {propertyData.length > 0 &&
            propertyData.map((e) => (
              <option
                className="form-control"
                key={e.id}
                name={e.title}
                value={e.title}
              >
                {e.title}
              </option>
            ))}
        </Form.Select>
        <p className="error">
          {formErrors.hasOwnProperty("property") ? formErrors.property : ""}
        </p>
      </Form.Group>
      <Form.Group className="input_padding" controlId="formBasicEmail">
        <Form.Control
          type="text"
          name="name"
          placeholder="Name"
          value={name}
          onChange={(e) => handleName(e)}
        />
        <p className="error">
          {formErrors.hasOwnProperty("name") ? formErrors.name : ""}
        </p>
      </Form.Group>
      <Form.Group className="input_padding" controlId="formBasicPassword">
        <Form.Control
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) => handleEmail(e)}
        />
        <p className="error">
          {formErrors.hasOwnProperty("email") ? formErrors.email : ""}
        </p>
      </Form.Group>
      <Form.Group className="input_padding" controlId="formBasicPassword">
        <PhoneInput
          placeholder="Phone Number"
          initialValueFormat="national"
          international
          defaultCountry="IN"
          //defaultValue={phoneNo}
          value={value}
          onChange={(value) => setValue(value)}
          //error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
        />
        <p className="error">
          {formErrors.hasOwnProperty("value") ? formErrors.value : ""}
        </p>
      </Form.Group>

     {/*  <Form.Group
        className="input_padding"
        controlId="formBasicPassword"
        style={{ marginTop: "20px" }}
      >
        <span className="otp_text text-success">{optMatchMsz}</span>
        <span className="otp_text text-danger">{setOptMismatchMsz}</span>

        <Form.Control
          type="text"
          name="otp"
          placeholder="OTP"
          value={otpValue}
          onChange={(e) => handleVarifyOtp(e)}
        />
        <span className="otp_text" onClick={(e) => handleOtp(e)}>
          Click to get one time Password (OTP)
        </span>
        {displayNone === true && (
          <span className="otp_text" onClick={(e) => handleOtp(e)}>
            Resend OTP
          </span>
        )}
        <br></br>
        <span className="otp_text text-danger">{errmsz}</span>
        <span className="otp_text text-success">{otpSendMsz}</span>
      </Form.Group>
       */}
      <Form.Group className="input_padding" controlId="formBasicPassword">
        <Form.Control
          type="text"
          name="message"
          placeholder="Message"
          value={message}
          onChange={(e) => handleMessage(e)}
        />
      </Form.Group>
      <Form.Text
        id="passwordHelpBlock"
        className="form_text"
        style={{ color: "#444444" }}
      >
        I authorize IRA Realty Tech Pvt Ltd and its representatives to Call,
        SMS, Email or WhatsApp me about its products and offers. This consent
        overrides any registration for DNC / NDNC
      </Form.Text>
      <div className="enquireformbtn_group">
        {colseButton ? (
          <div className="cancel_div" style={{ marginRight: "4%" }}>
            <button className="cancel_btn1" onClick={() => handleClose()}>
              Cancel
            </button>
          </div>
        ) : (
          <div style={{ marginLeft: "0%" }}></div>
        )}

        <div className={`${colseButton ? "btn_margin" : ""}`}>
          <button
            className={`${
              disabled === true
                ? "submit_btn1 attrAttribute"
                : "submit_btn_disabled attrAttribute"
            }`}
            style={!disableAttr ? { pointerEvents: "none" } : {}}
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormComponent;
