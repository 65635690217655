import React from 'react';
import {useMediaQuery} from 'react-responsive';
import HTMLReactParser from 'html-react-parser';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const VisionMission = ({section4}) => {
  const LaptopOrMobile = useMediaQuery({query: `(max-width: 768px)`});

  return (
    <section className="Vision_Mission about_welcome">
      <Container className="">
        <Row className="vision_Mission_row">
          <Col
            sm={12}
            lg={6}
            className="welcome_head_description order-lg-2 vision_head_description"
          >
            <h1 className="welcome_header">{section4.title}</h1>
            <div className="welcome_decription">
              {HTMLReactParser(section4.description, {
                replace: (domNode) => {
                  if (domNode.name === 'p') {
                  }
                },
              })}
            </div>
            <p></p>
            <p className="tag_line" style={{paddingRight: '0%'}}>
              
              {section4.tag_line}
              
            </p>
          </Col>

          <Col className="order-lg-1 vision_head_description">
            <img
              className={`${
                LaptopOrMobile ? 'vision_img_mobile' : 'vision_img'
              }`}
              src={section4.image_url}
              alt="Welcome Ira"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default VisionMission;
