import React from 'react'
import Container from 'react-bootstrap/Container';
import {Row,Button} from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import { BsArrowDownCircle } from 'react-icons/bs'
import { Markup } from 'interweave';

const Aspire = ({ section4 }) => {
    //console.log(section4,"section4")
    return (
        <Container style={{ marginTop: "60px" }}>
            <Row style={{paddingBottom: "40px"}}>
                <Col className='mt-5' lg={6} >
                    <h1 className='welcome_header project_highlights_title aspire-title'>
                        {section4.title}
                    </h1>
                    <Markup className="aspire-description" content={section4.description} />
                    <div className='downloadsection mt-3 d-md-flex'>
                        {section4.section_card !== undefined && section4.section_card
                            .map((element, index) => {
                                
                                return (
                                    <div key={index} sm={2} >
                                        {element.download_link != '' &&
                                            <a href={element.download_link} target="_blank" style={{ textDecoration: 'none' }}>
                                                <Button className='aspire_button mb-2 d-flex justify-content-center' to={element.download_link}>
                                                    <BsArrowDownCircle /> &nbsp;&nbsp;{element.download_title}
                                                </Button>
                                            </a>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </Col>
                <Col lg={6} className='welcome_ira_background_img background_img'>
                    <img className='welcome_ira_img img-box' src={section4.image_url} alt='Welcome Ira' />
                </Col>
            </Row>
        </Container>
    )
}
export default Aspire
