import React from 'react';
import axios from 'axios';
import Slider from './Slider';
import Plans from './Plans';
import ProjectHighlights from './ProjectHighlights';
import { useParams } from 'react-router-dom';
import Amenities from '../components/Amenities'
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../components/ErrorFallback';
import Specifications from './Specifications';
import Aspire from './Aspire';
import LiveProgress from './LiveProgress';
import DynamicComponentWithScript from './DynamicComponentWithScript';
import { Helmet } from 'react-helmet';

const ProjectDetails = () => {
    const { slug } = useParams();
		//const [slugState, setSlugState] = React.useState("")
    const [projectdetails, Setprojectdetails] = React.useState({});
    const api_call = React.useRef(true);
    const getprojectdetails = () => {
			axios.get(`api/Project/getProjectDetail/${slug}`)
				.then((response) => {
						Setprojectdetails(response.data.projectdetail);
				})
				.catch((err) => {
						console.log("error", err);
				});
    }

		
		
		var dataBot = '';
		if(slug === 'm3'){
			dataBot = '25444877';
		}else if(slug === 'miracle'){
			dataBot = '29684431';
		}else if(slug === 'aspiration'){
			dataBot = '28145980';
		}else if(slug === 'elevate'){
			dataBot = '25763817';
		}else if(slug === 'aerocity'){
			dataBot = '25654954';
		}else if(slug === 'gatewaytower'){
			dataBot = '25852443';
		}

    React.useEffect(() => {
			if (api_call.current) {
					api_call.current = false;
					getprojectdetails();
					//window.location.reload(false);
			}


    }, [projectdetails])
		
    return (
        <div className="projectDetailSection">
            {/* chatbot component */}
					{/* <div id="addChatbot"></div>
					<DynamicComponentWithScript
						slug={slug}
					 databot={dataBot}
					/> */}
            <ErrorBoundary FallbackComponent={ErrorFallback} >
                {projectdetails.section1 !== undefined && <Slider section1={projectdetails.section1} />}
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorFallback} >
                {projectdetails.section2 !== undefined && <ProjectHighlights section2={projectdetails.section2} />}
            </ErrorBoundary>
            {projectdetails.section3 !== undefined && projectdetails.section3.title !== "" &&
                <ErrorBoundary FallbackComponent={ErrorFallback} >
                    {(Object.keys(projectdetails.section3).length > 0) && <Plans section3={projectdetails.section3} />}
                </ErrorBoundary>
            }
            
            <ErrorBoundary FallbackComponent={ErrorFallback} >
                {(projectdetails.section4 !== undefined && Object.keys(projectdetails.section4).length > 0) && <Aspire section4={projectdetails.section4} />}
            </ErrorBoundary>

            <ErrorBoundary FallbackComponent={ErrorFallback} >
                {(projectdetails.section5 !== undefined && Object.keys(projectdetails.section5).length > 0) && <Amenities section5={projectdetails.section5} />}
            </ErrorBoundary>

            <ErrorBoundary FallbackComponent={ErrorFallback} >
                {(projectdetails.section6 !== undefined && Object.keys(projectdetails.section6).length > 0) && <Specifications section6={projectdetails.section6} />}
            </ErrorBoundary>

            <ErrorBoundary FallbackComponent={ErrorFallback} >
                {(projectdetails.section7 !== undefined && Object.keys(projectdetails.section7).length > 0) && <LiveProgress section7={projectdetails.section7} />}
            </ErrorBoundary>
        </div>
    );
};

export default ProjectDetails;