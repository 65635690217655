import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Container, Button} from 'react-bootstrap';
import {BsArrowLeftShort, BsArrowRightShort} from 'react-icons/bs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const TheTeam = ({section6}) => {
  
  const isDesktopOrLaptop = useMediaQuery({query: `(max-width: 960px)`});
  const [max_items] = React.useState(isDesktopOrLaptop ? 1 : 5);
  const [page, setpage] = useState(1);
  const [sectiondata, setsectiondata] = React.useState(section6.section_card);
  React.useEffect(() => {
    setpage(page === 0 ? 0 * max_items : page);

    setsectiondata(
      section6.section_card.slice((page - 1) * max_items, page * max_items)
    );
  }, [page]);

  return (
    <>
      <Container className="text-center">
        <Row className="welcome_ira">
          <Col className="welcome_head_description">
            <h1 className="welcome_header text-center">{section6.title}</h1>
          </Col>
        </Row>
        <Row>
          {Object.keys(section6.section_card).length > 0 &&
            section6.section_card.map((card, i) => (
              <Col lg={4} key={i}>
                <div className="About_wrapper">
                  <div className="About_card">
                    <div className="About_back">
                      <p className="Team_description2">
                        {card.smalldescription}
                      </p>
                      <p className="Founder-Name">{card.name}</p>
                      <p className="Founder">{card.designation}</p>
                    </div>
                    <div className="About_front">
                      <img
                        src={card.image_url}
                        alt="Manager"
                        className="manager-img"
                      />
                      <p className="Team-Founder-Name2">{card.name}</p>
                      <p className="Founder-Name2-Role">{card.designation}</p>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
        {/* {isDesktopOrLaptop && 
        <div
          className="about_prev_next_button"
          style={{backgroundColor: 'transparent'}}
         >
          <button
            style={
              page === 1
                ? {
                    color: 'gray',
                    pointerEvents: 'none',
                    backgroundColor: 'transparent',
                  }
                : {backgroundColor: 'transparent'}
            }
            className="About_prev_button"
            onClick={() => setpage((page) => page - 1)}
          >
            <BsArrowLeftShort
              className="arrowleft"
              style={
                page === 1
                  ? {
                      color: 'gray',
                      pointerEvents: 'none',
                      backgroundColor: 'transparent',
                    }
                  : {backgroundColor: 'transparent'}
              }
            />
            Prev
          </button>
          <button
            style={
              page * max_items < section6.section_card.length
                ? {backgroundColor: 'transparent'}
                : {
                    color: 'gray',
                    pointerEvents: 'none',
                    backgroundColor: 'transparent',
                  }
            }
            className="About_prev_button"
            onClick={() => setpage((page) => page + 1)}
          >
            Next{' '}
            <BsArrowRightShort
              className="arrowright"
              style={
                page * max_items < section6.section_card.length
                  ? {backgroundColor: 'transparent'}
                  : {
                      color: 'gray',
                      pointerEvents: 'none',
                      backgroundColor: 'transparent',
                    }
              }
            />
          </button>
        </div>
        } */}
      </Container>
    </>
  );
};

export default TheTeam;
