import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HTMLReactParser from 'html-react-parser';
import ReactHtmlParser  from 'html-react-parser';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from '../../components/ErrorFallback';
import DynamicComponentWithScript from '../../components/DynamicComponentWithScript';

const TermsConditions = () => {
  const api_call = useRef(true);
  const [termData, setTermData] = useState([]);

  const getTermSections = () => {
    axios
      .get('api/Home/getTermsConditions')
      .then((response) => {
        //console.log(response)
        setTermData(response.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  useEffect(() => {
    if (api_call.current) {
      api_call.current = false;
      getTermSections();
    }
  }, []);

  const html = Object.keys(termData).length > 0 && termData.privacypolicy.body;
  //console.log("html",html)
  const dataBot = "28955060";
  return (
    <>
      {/* <DynamicComponentWithScript
        slug=""
        databot={dataBot}
      /> */}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {termData.privacypolicy !== undefined && (
          <Container className="">
            <Row className="">
              <Col lg={12} className="">
                <h1 className="privacy_header" style={{textAlign: 'left'}}>
                  {termData.privacypolicy.title}
                </h1>
                <div className="privacy_decription">
                { ReactHtmlParser(html) }
                </div>
                {/* {HTMLReactParser(html, {
                  replace: (domNode) => {
                    // console.log(domNode);
                    // console.log(domNode.name);
                    if (domNode.name === 'ol') {
                      return (
                        <div
                          className="privacy_decription"
                          style={{textAlign: 'left'}}
                        >
                          {domNode.children[0].data}
                          
                          
                        </div>
                      );
                    }
                    

                  },
                })} */}
              </Col>
            </Row>
          </Container>
        )}
      </ErrorBoundary>
    </>
  );
};

export default TermsConditions;
