import React from 'react';
import {Container} from 'react-bootstrap';

const BlogHeader = ({data}) => {
  return (
    <div>
      {data !== undefined && (
        <div id="parent2">
          {'img_url' in data ? (
            <img
              className="blog_background_img"
              src={data.img_url}
              alt="blogs-background-img"
            />
          ) : (
            <img
              className="blog_background_img"
              src="/assets/Blogs-Background-Image.jpeg"
              alt="blogs-background-img"
            />
          )}
          <Container id="child2">
            <h1 className="Blogs">{data.title}</h1>
            <h3 className="Blogs-sub-text"> {data.descriptison}</h3>
          </Container>
        </div>
      )}
    </div>
  );
};

export default BlogHeader;
