import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from '../../components/ErrorFallback';
import JoinTheRevolution from '../../components/JoinTheRevolution';
import Welcome from '../../components/Welcome';
import VisionMission from '../../components/VisionMission';
import WhatSetsIraApart from '../../components/WhatSetsIraApart';
import TheTeam from '../../components/TheTeam';
import BlogHeader from '../../components/BlogHeader'
import DynamicComponentWithScript from '../../components/DynamicComponentWithScript';

const About = () => {
  const [aboutSections, setAboutSections] = useState({});
  const api_call = React.useRef(true);

  const getAboutSections = () => {
    axios
      .get('api/AboutUs/getAboutUsSections')
      .then((response) => {
        setAboutSections(response.data.aboutussections);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  useEffect(() => {
    if (api_call.current) {
      api_call.current = false;
      getAboutSections();
    }
  }, []);

  const dataBot = "28955060";

  return (
    <div>
      {/* <div id="addChatbot"></div>
      <DynamicComponentWithScript
        slug=""
        databot={dataBot}
      /> */}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div className='about_us'>
        {aboutSections.section1 !== undefined && (
          <BlogHeader data={aboutSections.section1} />
        )}
        </div>
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {aboutSections.section2 !== undefined && (
          <Welcome section2={aboutSections.section2} />
        )}
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {aboutSections.section3 !== undefined && (
          <JoinTheRevolution section3={aboutSections.section3} />
        )}
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {aboutSections.section4 !== undefined && (
          <VisionMission section4={aboutSections.section4} />
        )}
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {aboutSections.section5 !== undefined && (
          <WhatSetsIraApart section5={aboutSections.section5} />
        )}
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {aboutSections.section6 !== undefined && (
          <TheTeam section6={aboutSections.section6} />
        )}
      </ErrorBoundary>
    </div>
  );
};

export default About;
