import React from "react";
import { useMediaQuery } from "react-responsive";
import { Col, Row } from "react-bootstrap";
import ModalComp from "./ModalComp";
import DeviceOrientation, { Orientation } from "react-screen-orientation";

const Plans = ({ section3 }) => {
  const isDesktopOrMobile = useMediaQuery({ query: `(max-width: 991px)` });
  const [showModal, setshowModal] = React.useState(false);
  const ref = React.useRef(null);
  const [frame_data, setframe_data] = React.useState(section3.section_card[0]);
  const [tab_id, settab_id] = React.useState(section3.section_card[0].tab_id);

  const handleItem = (tab_id) => {
    setshowModal(true);
    setframe_data(
      section3.section_card.filter((item) => {
        return item.tab_id == tab_id;
      })[0]
    );
  };

  React.useEffect(() => {
    /*   settab_id(section3.section_card[0].tab_id);
        setframe_data(section3.section_card[0]); */
  }, [showModal]);

  window.onorientationchange = (event) => {
    if (event.target.screen.orientation.angle == 0) {
      setshowModal(false);
    }
    if (event.target.screen.orientation.angle == 90) {
    }
  };


  return (
    <section className="section_margin">
      <div className="plans">
        <h2
          style={{ textAlign: "center" }}
          className="welcome_header project_highlights_title"
        >
          {section3.title}
        </h2>
        <Row className="plans_row">
          <Col lg={3}>
            <Row ref={ref} className="plans-Rectangle">
              {section3.section_card.map((Element, index) => {
                return (
                  <Col
                    key={index}
                    lg={12}
                    xs={6}
                    className={
                      frame_data.tab_id == Element.tab_id && "active_plans"
                    }
                    onClick={() => handleItem(Element.tab_id)}
                  >
                    <div
                      className="active_plans_div"
                      style={{ marginLeft: "-11px" }}
                    >
                      <img className="plans-icons" src={Element.tab_icon} />
                      <p
                        style={
                          frame_data.tab_id == Element.tab_id
                            ? { color: "#BB5439" }
                            : {}
                        }
                        className="projectdetails_description"
                      >
                        {Element.tab_title}
                      </p>
                    </div>
                  </Col>
                );
              })}

              {(section3.section_card.length === 1 && !isDesktopOrMobile) && (
                <>
                  <Col lg={12} xs={6}>
                    <div
                      className="active_plans_div"
                      style={{ marginLeft: "-11px" }}
                    ></div>
                  </Col>
                  <Col lg={12} xs={6}>
                    <div
                      className="active_plans_div"
                      style={{ marginLeft: "-11px" }}
                    ></div>
                  </Col>
                </>
              )}
            </Row>
          </Col>

          <Col lg={9}>
            {isDesktopOrMobile ? (
              <DeviceOrientation lockOrientation={"landscape"}>
                {/* Will only be in DOM in landscape */}
                <Orientation orientation="landscape" alwaysRender={false}>
                  {frame_data.tab_id != undefined && (
                    <iframe
                      className="asd"
                      src={frame_data.tab_iframe_url}
                      width="100%"
                      height="100%"
                    ></iframe>
                  )}
                </Orientation>
                {/* Will stay in DOM, but is only visible in portrait */}
                <Orientation orientation="portrait" alwaysRender={false}>
                  {showModal && (
                    <div>
                      <ModalComp
                        showModal={showModal}
                        setshowModal={setshowModal}
                      />
                    </div>
                  )}
                </Orientation>
              </DeviceOrientation>
            ) : (
              frame_data.tab_id != undefined && (
                <iframe
                  key={frame_data.tab_id}
                  src={frame_data.tab_iframe_url}
                  width="100%"
                  height="100%"
                ></iframe>
              )
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Plans;
