import React, {useState, useRef, useEffect} from 'react'
import axios from 'axios';
import {Container, Row, Col} from 'react-bootstrap'
import ReactHtmlParser  from 'html-react-parser';

const Cancel = () => {
    const api_call = useRef(true);
    const [termData, setTermData] = useState([]);

    const getRefundSections = () => {
        axios
          .get('api/Home/getRefundCancellationPolicy')
          .then((response) => {
            //console.log(response)
            setTermData(response.data);
          })
          .catch((err) => {
            console.log('error', err);
          });
      };

      useEffect(() => {
        if (api_call.current) {
          api_call.current = false;
          getRefundSections();
        }
      }, []);  

			const html = Object.keys(termData).length > 0 && termData.privacypolicy.body;

    return (
        <>
        {termData.privacypolicy !== undefined && (
            <Container className="">
              <Row className="">
                <Col lg={12} className="text-left">
                  <h1 className="privacy_header" style={{textAlign: 'left'}}>
                    {termData.privacypolicy.title}
                  </h1>
                  <div className="privacy_decription">
                    { ReactHtmlParser(html) }
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </>
    )
}

export default Cancel
