import React from 'react';
import { useDispatch } from 'react-redux';
import {Button} from 'react-bootstrap';

const EnquireNowButton = ({buttonName}) => {
    const dispatch = useDispatch()

    const showform = () => {
        dispatch({ type: 'set', showModal: true })
    }

    return (
        <Button style={{backgroundColor: "#362A82", border: "none"}} onClick={() => showform()}>
        {buttonName}</Button>
        
    );
};

export default EnquireNowButton;