import React from 'react';
import Slider from '../components/Slider';
import Glimpse from '../components/Glimpse';
import FeaturedProjects from '../components/FeaturedProjects';
import Whyira from '../components/Whyira';
import axios from 'axios';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../components/ErrorFallback';
import JoinTheRevolution from '../components/JoinTheRevolution';
import DynamicComponentWithScript from '../components/DynamicComponentWithScript';
const Home = () => {

    const [HomeSections, SetHomeSections] = React.useState({});
    const api_call = React.useRef(true);
    const getHomeSections = () => {
        axios.get('api/home/getHomeSections')
            .then((response) => {
                SetHomeSections(response.data.homesections);
            })
            .catch((err) => {
                console.log("error", err);
            });
    }
    React.useEffect(() => {
        if (api_call.current) {
            api_call.current = false;
            getHomeSections();
        }
    }, [])

		const dataBot = "28955060";
		


    return (
        <div>
            {/* chatbot component */}
            {/* <div id="addChatbot"></div>
						<DynamicComponentWithScript
								slug=""
								databot={dataBot}
						/> */}

            <ErrorBoundary FallbackComponent={ErrorFallback} >
                {HomeSections.section1 !== undefined && <Slider section1={HomeSections.section1} />}
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorFallback} >
                {HomeSections.section2 !== undefined && <Glimpse section2={HomeSections.section2} />}
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorFallback} >
                {HomeSections.section3 !== undefined && <FeaturedProjects section3={HomeSections.section3} />}
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorFallback} >
                {HomeSections.section4 !== undefined && <Whyira section4={HomeSections.section4} />}
            </ErrorBoundary>
            <div className='homeRevolution'>
                <ErrorBoundary FallbackComponent={ErrorFallback} >
                    {HomeSections.section5 !== undefined && <JoinTheRevolution section3={HomeSections.section5} />}
                </ErrorBoundary>
            </div>
        </div>
    );
};

export default Home;