import React from 'react'
import {Container, Card, Row, Col } from 'react-bootstrap';
/* import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; */
import { Markup } from 'interweave';

function Whyira({ section4 }) {
  return (
    <Container className='section_margin'>
      <div style={{marginBottom: '40px'}}>
        <h1 className='Whyira_header whyira'>
          {section4.title}
        </h1>
      </div>
      <Row className=''>
      {section4.section_card
          .map((element, index) => {
            return (
              <Col lg={3} md={6} sm={12} key={index} className='whyIra mb-3'>
                <div className='whyicon_box' data-aos="flip-left" data-aos-delay="200" >
                  <div className='icon'>
                    <img src={element.icon_url} className='center'/>
                  </div>
                  <h3 className='title1 pt-3'>{element.title }</h3>
                  <div className='description1 pt-3'>
                    <Markup content={element.description }/>
                  </div>
                </div>
              </Col>
            )
          })
      }
        
      </Row>
    </Container>
  );
};
export default Whyira;