import React from 'react';
import Container from 'react-bootstrap/Container';

import HTMLReactParser from 'html-react-parser';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Welcome = (section2) => {
  return (
    <Container className="about_welcome about_margin">
      <Row className="welcome_ira">
        <Col xs={12} lg={6} className="welcome_head_description order-lg-2">
          <h1 className="welcome_header">{section2.section2.title}</h1>
          <div className="welcome_decription">
            {HTMLReactParser(section2.section2.description, {
              replace: (domNode) => {
                if (domNode.name === 'p') {
                }
              },
            })}
          </div>
        </Col>
        <Col className="welcome_ira_background_img">
          <img
            className="welcome_ira_img"
            src={section2.section2.image_url}
            alt="Welcome Ira"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Welcome;
