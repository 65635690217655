import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import {useMediaQuery} from 'react-responsive';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from '../../components/ErrorFallback';
import {Container, Row, Col} from 'react-bootstrap';
import EnquireNowButton from '../../components/EnquireNowButton'
import {Markup} from 'interweave';
import JoinTheRevolution from '../../components/JoinTheRevolution';
import BlogHeader from '../../components/BlogHeader'
import DynamicComponentWithScript from '../../components/DynamicComponentWithScript';

const JoinRevolution = () => {
  const [joinData, setJoinData] = useState({});
  const LaptopOrMobile = useMediaQuery({query: `(max-width: 567px)`});
  const api_call = React.useRef(true);

  const getJoinSections = () => {
    axios
      .get('api/getJoinTheRevolution')
      .then((response) => {
        setJoinData(response.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  useEffect(() => {
    if (api_call.current) {
      api_call.current = false;
      getJoinSections();
    }
  }, []);

  const dataBot = "28955060";
  return (
    <div>
      {/* <DynamicComponentWithScript
        slug=""
        databot={dataBot}
      /> */}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
          {("jointherevolution" in joinData) && ("section1" in joinData.jointherevolution) && (
              <BlogHeader data={joinData.jointherevolution.section1
        } />
         
        )} 
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
      {("jointherevolution" in joinData) && ("section2" in joinData.jointherevolution) && (
        <JoinTheRevolution section3={joinData.jointherevolution.section2} />
      )
      }
        
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {("jointherevolution" in joinData) && ("section3" in joinData.jointherevolution) && (
          <Container className="about_welcome text-center">
            <Row className="welcome_ira">
              <Col lg={12} className="welcome_head_description">
                <h1 className="welcome_header text-center">
                  {joinData.jointherevolution.section3.title}
                </h1>
                <Markup
                  className="welcome_decription"
                  style={{marginBottom: '16px', marginTop: '0px'}}
                  content={joinData.jointherevolution.section3.description}
                />
              </Col>
              {joinData.jointherevolution.section3.section_card.map(
                (card, i) => (
                  <Col
                    lg={3}
                    sm={12}
                    className="d-flex align-items-stretch justify-content-center"
                    key={i}
                  >
                    <div className="icon_box">
                      <div className="icon-about">
                        <img
                          className="icon_img"
                          src={card.icon_url}
                          alt="card-icon"
                        />
                      </div>
                      <Markup
                        className="welcome_decription"
                        content={card.description}
                      />
                    </div>
                  </Col>
                )
              )} 
            </Row>
          </Container>
        )}
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {("jointherevolution" in joinData) && ("section4" in joinData.jointherevolution) && (
          <section className='Vision_Mission about_welcome' >
          <Container className="Join-IRA-Today text-center">
            <Row>
              <Col className=''>
                <Markup
                  className="welcome_decription"
                  content={joinData.jointherevolution.section4.description}
                />
                 {joinData.jointherevolution.section4.tag_line !== undefined && 
                 <p className='tag_line mx-auto  ' style={{paddingRight:'0px'}}>{joinData.jointherevolution.section4.tag_line}</p>}
                 {joinData.jointherevolution.section4.enable_enq_button == 'yes' &&
                    <div className="slider_enquirebtn">
                      <EnquireNowButton buttonName={joinData.jointherevolution.section4.enq_button_text}/>
                    </div>
                  }
              </Col>
            </Row>
          </Container>
          </section>
        )}
      </ErrorBoundary>
    </div>
  );
};

export default JoinRevolution;
