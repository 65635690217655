import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useMediaQuery } from 'react-responsive';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Amenities = ({ section5 }) => {

  
  const LaptopOrMobile = useMediaQuery({ query: `(max-width: 991px)` });

  const [categoryId, setCategoryId] = useState(
    section5.section_card != undefined ?
    section5.section_card.category[0].category_id : 0
  );
  const [categoryData, setCategoryData] = useState(
    section5.section_card != undefined ?
    section5.section_card.category[0].amenities : []
  );

  const handelCategory = (e, id) => {

    setCategoryId(id);
    const array = section5.section_card.category.filter(
      (itemId) => itemId.category_id === id
    );

    setCategoryData(array[0].amenities);
  };

  return (
    <div className='section_margin'>
      <Container className={`${LaptopOrMobile ?
        "project-about_welcome" : "about_welcome"}`}>
        <Row className="welcome_ira">
          <Col className="welcome_head_description">
            <h1 className="welcome_header amenities_plans text-center">{section5.title}</h1>
          </Col>
        </Row>
      </Container>
      <Container

        style={{


          backgroundColor: `${LaptopOrMobile && '#FEF4F1'}`
        }}

      >
        <Row>

          <Col lg={3} sm={12}
            className={`${LaptopOrMobile ?
              "project-amenities-Rectangle_mobile" :
              //"project-amenities-Rectangle

              ""}`}

            style={{
              width: `${!LaptopOrMobile && '24%'}`,
              display: 'flex', alignItems: 'stretch' //, height: "100%"

            }}
          >
            {section5.section_card != undefined && 
            <div className={`${LaptopOrMobile ? "project-selected-feature_mobile" : "project-selected-feature "}`}>
              {section5.section_card.category.map((item, ind) => {
                return (
                  <React.Fragment key={item.category_id}>
                    <div

                      style={{
                        maxWidth: `${LaptopOrMobile && (item.title.length >= 11 &&
                          '40vw')
                          }`

                      }}

                      className={`${LaptopOrMobile ? "project-selected-feature_mobile" : "amenities-selected-project"}`}>
                      <button
                        className="project-amenities-description project-text-overflow" style={{


                          borderTopLeftRadius: `${LaptopOrMobile && (ind == 0 &&
                            '4px')
                            }`,
                          borderBottomLeftRadius: `${LaptopOrMobile && (ind == 0 &&
                            '4px')
                            }`,
                          borderTopRightRadius: `${LaptopOrMobile && (section5.section_card.category.length == ind + 1 &&
                            '4px')
                            }`,
                          borderBottomRightRadius: `${LaptopOrMobile && (section5.section_card.category.length == ind + 1 &&
                            '4px')
                            }`,

                          color: `${LaptopOrMobile ? (categoryId === item.category_id ?
                            '#fff' : 'rgba(48, 48, 48, 0.6)') : categoryId === item.category_id
                            ? '#362A82'
                            : 'rgba(48, 48, 48, 0.6)'
                            }`,
                          backgroundColor: `${LaptopOrMobile && categoryId === item.category_id ?
                            '#362A82' : '#fff'}`, width: `${LaptopOrMobile && '100%'}`,
                          textAlign: `${LaptopOrMobile && 'center'}`,
                          fontSize: `${LaptopOrMobile && '14px'}`,
                          padding: `${LaptopOrMobile && '9px 0'}`,
                          // border: `${LaptopOrMobile ? '1px solid rgba(0,0,0,.6)' : 'none'}`,
                        }}
                        defaultValue={item.category_id}
                        name={categoryId}
                        onClick={(e) => handelCategory(e, item.category_id)}
                      >
                        {item.title}
                      </button>
                    </div>

                    {section5.section_card != undefined && section5.section_card.category.length != ind + 1 && <hr />}
                  </React.Fragment>
                );
              })}
            </div>
            }
          </Col>

          <Col
            sm={12} xs={12} lg={9}
            className="mt-md-0 project-amenities-Rectangle1"


            style={{
              marginLeft: `${!LaptopOrMobile && '1%'}`,
              boxShadow: `${LaptopOrMobile && 'none'}`,

            }}

          >

          {section5.section_card != undefined && 
            <div className="project-amenities-Rectangle2">
              <Row>
                {categoryData.map((item1, index) => (
                  <Col key={index} md={4} sm={6} xs={6} className="mt-4 mt-md-0 mb-4">
                    <div className="d-flex">
                      <img
                        src={item1.icon_url}
                        alt="Amenities Icon"
                        style={{
                          width: '18.5px',
                          height: '21.5px',
                          marginRight: '5px',
                        }}
                      />
                      <p className="project-amenities-title">{item1.title}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          }
          </Col>
        </Row>
      </Container>
    </div >
  );
};

export default Amenities;
