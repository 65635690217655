import React from 'react';

const useFeaturedProjecthook = (section3) => {
    const [locationData, setlocationData] = React.useState('');
    const [categoryData, setcategoryData] = React.useState({});
    const [projectLists, setprojectLists] = React.useState({});
    const [projectData, setprojectData] = React.useState({});
    const initial_ref = React.useRef(true);


    React.useEffect(() => {
        if (initial_ref.current) {
            initial_ref.current = false;

            /* setTimeout(function(){
                var divElement = document.querySelector(".featured_projects_left");
                var elemHeight = divElement.offsetHeight;
    
                document.querySelector('.Featured-Projects-card2-background-Image').style.height = elemHeight + 'px';  
            }, 2000); */

            

            setlocationData(section3.section_card[0].location);
            setcategoryData(section3.section_card[0]);
            setprojectLists(section3.section_card[0].category[0]);
            setprojectData(section3.section_card[0].category[0].projects[0]);
        }
    }, [locationData])

    const handleItem = (id, item) => {
        
        setcategoryData(section3.section_card.filter((item) => {
            return item.location_id == id
        })[0]);

        setprojectLists(section3.section_card.filter((item) => {
            return item.location_id == id
        })[0].category[0]);

        setprojectData(section3.section_card.filter((item) => {
            return item.location_id == id
        })[0].category[0].projects[0]);

        setlocationData(item);
    }

    // console.log("categoryData", categoryData)
    // console.log("projectLists", projectLists)
    // console.log("projectData", projectData)

    const handleProjectLists = (id) => {

        /* setTimeout(function(){
            var divElement = document.querySelector(".featured_projects_left");
            var elemHeight = divElement.offsetHeight;

            document.querySelector('.Featured-Projects-card2-background-Image').style.height = elemHeight + 'px';  
        }, 10); */

        setprojectLists(
            section3.section_card.reduce((prev, product) => prev || product.category.find(item => item.category_id === id), undefined))
    }

    const handleProjectData = (id) => {
        setprojectData(projectLists.projects.filter(item => item.project_id === id)[0])
    }

    return {
        locationData,
        handleItem,
        categoryData,
        projectLists,
        projectData,
        handleProjectLists,
        handleProjectData,
    }
};

export default useFeaturedProjecthook;