import React from 'react';
import { Dropdown } from 'react-bootstrap';

import useFeaturedProjecthook from './FeaturedProjecthook';

const HandleProjectLists = ({ section3, GetProjectData }) => {

    const { locationData, handleItem, categoryData, projectLists, projectData, handleProjectLists, handleProjectData } = useFeaturedProjecthook(section3);

    React.useEffect(() => {
        GetProjectData(projectData);
    }, [projectData])

    return (
        <div className='featured_projects_left'>
            <div className='featured-projects-dropdown'>
                <Dropdown
                >
                    <Dropdown.Toggle className='dropbtn'>{locationData}</Dropdown.Toggle>
                    <Dropdown.Menu >
                        {
                            section3.section_card.map((Element, index) => {
                                return (
                                    <Dropdown.Item key={index} as="button" onClick={() => handleItem(Element.location_id, Element.location)}>
                                        {Element.location}
                                    </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
                {
                    categoryData.category != undefined && categoryData.category.map((Element, index) => (
                        <React.Fragment key={index}>
                            <h2 style={Element.category_id == projectLists.category_id ? { color: 'rgb(54, 42, 130)' } : {}}
                                onClick={() => handleProjectLists(Element.category_id)} className='featured-project-card1-header'>{Element.title}</h2>
                            <div>
                                {
                                    projectLists.projects != undefined && Element.category_id == projectLists.category_id && projectLists.projects.map((Element2, index1) => (
                                        <p
                                            key={index1}
                                            style={Element2.project_id == projectData.project_id ?
                                                { fontWeight: '600' } : { cursor: "pointer" }}

                                            onClick={() => handleProjectData(Element2.project_id)} className='featured-project-card1-option'>{Element2.title}</p>
                                    ))
                                }
                            </div>
                            <hr className='featured-projects-hr'></hr>
                        </React.Fragment>
                    ))
                }
            </div>
        </div>
    );
};

export default HandleProjectLists;