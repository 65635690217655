import React from 'react';
import {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {FaFacebookF} from 'react-icons/fa';
import FormComponent from '../../components/FormComponent';
import {BsYoutube} from 'react-icons/bs';
import {AiFillInstagram} from 'react-icons/ai';
import {BsTwitter} from 'react-icons/bs';
import {FaLinkedinIn} from 'react-icons/fa';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ChanelPartnerForm from '../../components/ChanelPartnerForm';

const Footer = () => {
  const [footer] = useState(false)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  

  
  return (
    <>
      <Container fluid className="footer" >
        <Row style={{paddingTop: '40px'}}>
          <Col
            md={12}
            lg={3}
            className="order-md-2 order-lg-5"
            style={{marginBottom: '30px'}}
          >
            <h3 className="footer-header">ENQUIRE NOW</h3>

            <FormComponent colseButton={footer}/>
          </Col>
          <Col
            xs={12}
            md={4}
            lg={3}
            className="order-md-1 order-lg-1"
            style={{marginBottom: '30px'}}
          >
            <h3 className="footer-header">CONTACT</h3>
            <p className="footer-link-para">
              <strong>Email:</strong>{' '}
              <a
                href="mailto: info@irarealty.in"
                target="_blank" rel="noreferrer"
                style={{color: '#383838'}}
                className="footer-ira-reality"
              >
                info@irarealty.in
              </a>
              <br />
              <strong>Phone:</strong>{' '}
              <a href="tel:+919121777777" style={{color: '#383838'}} className="footer-ira-reality">
                {' '}
                +91 9121777777
              </a>
              <br />
              <strong>Location: </strong><span className="footer-ira-reality"> IRA Realty Tech Pvt.Ltd,</span>
              <br />
              <strong>H.No: </strong><span className="footer-ira-reality"> 4-49/2,</span>
              <br />
              <span className="footer-ira-reality pt-2"> Besides Anvaya Conventions Rd,</span>
              <br />
              <span className="footer-ira-reality"> Financial District, Vattinaguapally,</span>
              <br />
              <span className="footer-ira-reality"> Hyderabad, Telangana 500032 </span><br />
            </p>
          </Col>
          <Col
            xs={6}
            md={3}
            lg={2}
            className="order-md-1 order-lg-2"
            style={{marginBottom: '30px'}}
          >
            <h3 className="footer-header">PROJECTS</h3>
            <p className="footer-link-para">
              <NavLink className="footer-ira-reality" to="project/apartments">
                Apartments
              </NavLink>
              <br />
              <NavLink className="footer-ira-reality" to="project/villas">
                Villas
              </NavLink>
              <br />
              <NavLink className="footer-ira-reality" to="project/townships">
                Townships
              </NavLink>
              <br />
              <NavLink className="footer-ira-reality" to="project/commercial">
              Commercial
              </NavLink>
              <br />
              <NavLink className="footer-ira-reality" to="project/farms">
              Farms
              </NavLink>
            </p>
          </Col>
          <Col
            xs={6}
            md={3}
            lg={2}
            className="order-md-1 order-lg-3"
            style={{marginBottom: '30px'}}
          >
            <h3 className="footer-header">IRA REALITY</h3>
            <div className="footer-link-para">
              <NavLink className="footer-ira-reality" to="/about-ira">
                About IRA
              </NavLink>
              <br />
              <NavLink className="footer-ira-reality" to="/join-the-revolution">
                Brand Story
              </NavLink>
              <br />
              <div className="footer-ira-reality channelpartner" onClick={() => handleShow()}>
                CP Registration
              </div>
              <br />
              <NavLink className="footer-ira-reality" to="/career">
                Careers
              </NavLink>
            </div>
          </Col>

          <Col
            xs={6}
            md={2}
            lg={2}
            className="order-md-1 order-lg-4"
            style={{marginBottom: '30px'}}
          >
            <h3 className="footer-header">RESOURCES</h3>
            <p className="footer-link-para">
              <NavLink className="footer-ira-reality" to="/blogs">
                Blogs
              </NavLink>
              <br />
              <NavLink className="footer-ira-reality" to="/media">
                Media
              </NavLink>
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <div style={{margin: '0 auto', width: 260, paddingBottom: '30px', paddingTop: '37px'}}>
              <a
                href="https://www.facebook.com/IRARealtyIndia"
                aria-label="Facebook"
                target="_blank"
                rel="noreferrer" 
              >
                <FaFacebookF className="social-media-icons" />
              </a>
              <a
                href="https://twitter.com/irarealtyindia"
                aria-label="Twitter"
                target="_blank"
                rel="noreferrer"
              >
                <BsTwitter className="social-media-icons" />
              </a>
              <a
                href="https://www.linkedin.com/company/irarealtyindia"
                aria-label="LinkedIn"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn className="social-media-icons" />
              </a>
              <a
                href="https://www.youtube.com/channel/UC7fWHYHpQhJzFTjtEBchDuQ"
                aria-label="YouTube"
                target="_blank"
                rel="noreferrer"
              >
                <BsYoutube className="social-media-icons" />
              </a>
              <a
                href="https://www.instagram.com/irarealtyindia/"
                aria-label="Instagram"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillInstagram className="social-media-icons" />
              </a>
            </div>
          </Col>
        </Row>
        
      </Container>
      <Container fluid className='privacy_section'>
        <Row>
          <Col className="text-center d-md-flex justify-content-md-between privacy_section_text">
              <p>
                <NavLink to="/privacy-policy" style={{color: '#444444', textDecoration: 'none'}}>
                  Privacy Policy
                </NavLink>{' '}
                |{' '}
                <NavLink to="/terms-and-conditions" style={{color: '#444444', textDecoration: 'none'}}>
                  Terms &amp; Conditions
                </NavLink>
                |{' '}
                <NavLink to="/refund-and-cancelation-conditions" style={{color: '#444444', textDecoration: 'none'}}>
                  Refund &amp; Cancellation Policy
                </NavLink>
                
              </p>
              <p>
                Powered By{' '}
                <strong>
                  <a
                    href="https://www.irax.in/"
                    style={{color: '#ff9933', textDecoration: 'none'}}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Irax
                  </a>
                </strong>
              </p>
          </Col>
        </Row>
      </Container>
      {show && <ChanelPartnerForm  show={show} setShow={setShow}/>}
      
    </>
  );
};

export default Footer;

