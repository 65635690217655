import React from 'react';
import {NavLink} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HTMLReactParser from 'html-react-parser';
import {Markup} from 'interweave';
import {AiOutlineArrowRight} from 'react-icons/ai';

const JoinTheRevolution = ({section3}) => {
  const smallDeviceData = section3.description.substr(0, 215);
  const largeDeviceData = section3.description.substr(305, 984);

  return (
    <Container className="about_welcome section_margin">
      <Row className="welcome_ira">
        <Col lg={6} className="welcome_head_description">
          <h1 className="welcome_header">{section3.title}</h1>
          {'sub_title' in section3 && (
            <p className="tag_line pb-4">
              {'"'}
              {section3.sub_title}
              {'"'}
            </p>
          )}

          <div className="welcome_decription pb-4 join_revolution">
            <Markup className="welcome_decription" content={smallDeviceData} />
          </div>
          <div className="welcome_decription pb-4 join_revolution_data">
            {HTMLReactParser(section3.description, {
              replace: (domNode) => {
                if (domNode.name === 'p') {
                }
              },
            })}
          </div>

            {'tag_line' in section3 && section3.tag_line !== '' && 
            <Markup
                  className="tag_line"
                  
                  content={section3.tag_line}
                />}
          
            

          {section3.redirect_url !== undefined && (
            <div className="read_div">
              <NavLink className="read_link" to="/join-the-revolution">
                Read More <AiOutlineArrowRight />
              </NavLink>
            </div>
          )}
        </Col>
        <Col lg={6}>
          <img
            className="welcome_ira_img"
            src={section3.image_url}
            alt="Join the Revolution"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default JoinTheRevolution;
