import React from 'react';
import {Markup} from 'interweave';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HTMLReactParser from 'html-react-parser';

const WhatSetsIraApart = ({section5}) => {
  return (
    <Container className="about_welcome text-center">
      <Row className="welcome_ira text-center">
        <Col lg={12} className="welcome_head_description">
          <h1 className="welcome_header text-center">{section5.title}</h1>
          <div className="welcome_decription"
            style={{marginBottom: '16px', marginTop: '0px', textAlign: 'center'}}>
          <Markup content={section5.description} />
          </div>
          
        </Col>
        <>
          {section5.section_card.map((card, i) => (
            <Col
              lg={4}
              xs={6}
              className="d-flex align-items-stretch justify-content-center"
              key={i}
            >
              <div className="icon_box">
                <div className="icon-about">
                  <img
                    className="icon_img"
                    src={card.icon_url}
                    alt="card-icon"
                  />
                </div>
                {HTMLReactParser(card.description, {
                  replace: (domNode) => {
                    if (domNode.name === 'p') {
                    }
                    if (domNode.name === 'b') {
                    }
                  },
                })}
              </div>
            </Col>
          ))}
        </>
      </Row>
    </Container>
  );
};

export default WhatSetsIraApart;
