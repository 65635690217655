import React from 'react';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Markup } from 'interweave';
import { useMediaQuery } from 'react-responsive';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { BsFillCircleFill } from 'react-icons/bs'
//const now = 60;

/* import PropTypes from 'prop-types' */


const  LiveProgress = ({ section7 }) => {
    const [activeIndex, setactiveIndex] = React.useState(0);
    const LaptopOrMobile = useMediaQuery({ query: `(max-width: 567px)` });


    return (
        //{section7.gallery !== '' }
        <Container className='section_margin container_image'>
            <Row className='px-2'>
                <Col className="body_style" xs={12} lg={6}>
                    <h1 className='welcom_header project_highlights_title LiveProgress_title'>
                        {section7.title}
                    </h1>
                    <div className='LiveProgress_description'>
                        <Markup className='LiveProgress_description ' content={section7.description} /><br/><br/>
                    </div>
                    <Col className='Circle'>
                        <div><BsFillCircleFill className='Circle_Plan'/>
                            <span className='plan_font'> Plan</span>
                        </div>
                        <div><BsFillCircleFill className='Finished_Circle'/>
                            <span className='Finished_font'> Finished</span>
                        </div>
                    </Col>

                    <Col className='Structures_Circle'>
                        <Row style={{width: '100%'}}>
                            <Col>
                                <span className='paragrapg'>Structures</span>
                            </Col>
                            <Col>
                                <div className='Struc_perse'>
                                    <div ><BsFillCircleFill className='Circle_Plan' />
                                        <span className='persentsge_color'>{section7.str_plan}%</span>
                                    </div>
                                    <div ><BsFillCircleFill className='Finished_Circle'/>
                                        <span className='persentsge_color'>{section7.str_finished}%</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/* <div className='paragrapg'> Structures </div>
                        <div className='Struc_perse'>
                            <div ><BsFillCircleFill className='Circle_Plan' />
                                <span className='persentsge_color'>{section7.str_plan}%</span>
                            </div>
                            <div ><BsFillCircleFill className='Finished_Circle'/>
                                <span className='persentsge_color'>{section7.str_finished}%</span>
                            </div>
                        </div> */}
                    </Col>

                    <ProgressBar className='ProgressBar_white'>
                        <ProgressBar className="finishStatus" now={section7.str_finished}/>
                        <ProgressBar className="planStatus" now={section7.str_plan}/>
                    </ProgressBar>
                    <p className='year'>{section7.finished}</p>

                    <Col className='Finishes_Circle'>
                        <Row style={{width: '100%'}}>
                            <Col>
                                <span className='paragrapg'>Finishes</span>
                            </Col>
                            <Col>
                                <div className='Struc_perse'>
                                    <div ><BsFillCircleFill className='Circle_Plan' />
                                        <span className='persentsge_color'>{section7.fini_plan}%</span>
                                    </div>
                                    <div ><BsFillCircleFill className='Finished_Circle'/>
                                        <span className='persentsge_color'>{section7.fini_finished}%</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/* <div className='paragrapg'>Finishes{"   "}</div>
                        <div className='Struc_perse'>
                            <div ><BsFillCircleFill className='Circle_Plan' />
                                <span className='persentsge_color'>{section7.fini_plan}%</span>
                            </div>
                            <div ><BsFillCircleFill className='Finished_Circle'/>
                                <span className='persentsge_color'>{section7.fini_finished}%</span>
                            </div>
                        </div> */}
                    </Col>

                    <ProgressBar className='ProgressBar_white'>
                        <ProgressBar className="finishStatus"  now={section7.fini_finished}/>
                        <ProgressBar className="planStatus" now={section7.fini_plan}/>
                    </ProgressBar>

                    <Col className='Amenities_Circle'>
                        <Row style={{width: '100%'}}>
                            <Col>
                                <span className='paragrapg'>Amenities</span>
                            </Col>
                            <Col>
                                <div className='Struc_perse'>
                                    <div ><BsFillCircleFill className='Circle_Plan' />
                                        <span className='persentsge_color'>{section7.ame_plan}%</span>
                                    </div>
                                    <div ><BsFillCircleFill className='Finished_Circle'/>
                                        <span className='persentsge_color'>{section7.ame_finished}%</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/* <div className='paragrapg'>Amenities{"  "}</div>
                        <div className='Struc_perse'>
                            <div ><BsFillCircleFill className='Circle_Plan' />
                                <span className='persentsge_color'>{section7.ame_plan}%</span>
                            </div>
                            <div ><BsFillCircleFill className='Finished_Circle'/>
                                <span className='persentsge_color'>{section7.ame_finished}%</span>
                            </div>
                        </div> */}
                    </Col>

                    <ProgressBar className='ProgressBar_white'>
                        <ProgressBar className="finishStatus" now={section7.ame_finished} />
                        <ProgressBar className="planStatus" now={section7.ame_plan}/>
                    </ProgressBar>

                </Col>
                <Col className="mt-4" lg={6} md={12} >
                    <div className='LiveProgress_background_img'>
                        <Carousel>
                            {section7.gallery !== undefined && section7.gallery
                                .map((element, index) => {
                                    return (
                                        <Carousel.Item key={index} className='project-amenities-Rectangle2 '>

                                            <img className="d-block LiveProgress_img" src={element} />

                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default LiveProgress;
