import React from 'react';
import { Container } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

const ModalComp = ({ showModal, setshowModal }) => {
    const handleClose = () => {
        setshowModal(false);
    }

    React.useEffect(() => {
        if (showModal) {
            const interval = setInterval(() => {
                handleClose();
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [showModal])

    return (
        <div>
            <Modal className='rotatemodal ml-5 mr-5 mt-3' show={showModal} >
                <Modal.Body >
                    <button className='close' onClick={() => handleClose()}>
                        <span>x</span>
                    </button>
                    <Container>
                        <div className='modal-card'>
                            <img src="https://www.irarealty.in/assets/images/portrait-mode.png" />
                            <h2 className='projectdetails_description' style={{ fontSize: "20px", textAlign: "center", marginTop: "20px" }}>
                                Portrait mode is not supported. Please rotate your device to landscape mode.
                            </h2>
                        </div>
                    </Container>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ModalComp;