import React, {useState, useRef, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import axios from 'axios';
import BlogHeader from '../../components/BlogHeader';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from '../../components/ErrorFallback';
import {Form} from 'react-bootstrap';
import DynamicComponentWithScript from '../../components/DynamicComponentWithScript';


const Media = () => {
  const api_call = useRef(true);
  const [mediaData, setMediaData] = useState({});
  const [portfoliData, setPortFoliData] = useState([]);
  
  const getMediaSections = () => {
    axios
      .get('api/getMediaDetails')
      .then((response) => {
        setMediaData(response.data);
        setPortFoliData(response.data.header.section2.media);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  useEffect(() => {
    if (api_call.current) {
      api_call.current = false;
      getMediaSections();
    }
  }, []);

  const handelLanguageChange = (e) => {
    console.log(e.target.value, 'e.target.value')
    setPortFoliData(
      e.target.value === ''
        ?  mediaData.header.section2.media 
        : mediaData.header.section2.media.filter(
            (each) => each.language === e.target.value
          )
    );
  };

  const dataBot = "28955060";

  return (
    <div>
      {/* <DynamicComponentWithScript
          slug=""
          databot={dataBot}
      /> */}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {mediaData.header !== undefined && (
          <BlogHeader data={mediaData.header.section1} />
        )}
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {mediaData.header !== undefined && (
          <Container
            className="d-flex justify-content-around"
            style={{marginTop: '60px'}}
          >
            <h1 className="welcome_header media_header">
              {mediaData.header.section2.title}
            </h1>
            <Form.Select style={{width: 'auto'}}
              className="media_drop_down"
              onChange={(e) => handelLanguageChange(e)}
            >
              <option value="">All</option>
              {mediaData.header.section2.language.map((each, i) => (
                <option key={i} value={each}>
                  {each}
                </option>
              ))}
            </Form.Select>
          </Container>
        )}
      </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
        {mediaData.header !== undefined && (
          <Container style={{marginTop: '20px'}}>
            <Row>
              {portfoliData.map((each, i) => (
                <Col
                  lg={4}
                  md={6}
                  key={i}
                  className="media-portfolio-container"
                >
                 <a href={each.redirect_url} target='_blank'>
                 <div className="media-portfolio-wrap">
                    
                      <img
                        src={each.backgroud_image}
                        alt="realtor in kollur"
                        className="media_image text-center"
                      />
                   
                    <div className="media-portfolio-info">
                     <p className='media_portfolio_description'>{each.description}</p>
                      <p className='media_portfolio_description'>
                        {each.sub_description} | {each.date}
                      </p>
                      <button className="media_link" style={{border:"none"}}>
                        View Article
                      </button>
                    </div>
                  </div>
                 </a>                  
                </Col>                
              ))}
            </Row>
          </Container>
        )}
      </ErrorBoundary>  
    </div>
  );
};

export default Media;


 