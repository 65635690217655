import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Markup } from 'interweave';

const HtmlText = ({ text, length = 360 }) => {
    const [showLess, setShowLess] = React.useState(true);

    if (text.length < length) {
        return <span className='specifications_description'>
            <p dangerouslySetInnerHTML={{
                __html: text
            }}></p>
        </span>;

    }

    return (
        <span className='specifications_description'>
            <p
                dangerouslySetInnerHTML={{
                    __html: showLess ? `${text.slice(0, length)}...` : text,
                }}
            ></p>
            <a
                className='read_more_btn'
                onClick={() => setShowLess(!showLess)}
            >
                &nbsp;Read {showLess ? "More" : "Less"}
            </a>
        </span>
    );
};


function sDesktop({ section6 }) {

    return (
        <Container className='section_margin'>
            <Col className="mt-5">
                <h1 className='welcome_header project_highlights_title' style={{ textAlign: "center" }}>
                    {section6.title}
                </h1>
            </Col>
            <Row className="specification_sec">
                {section6.section_card != undefined && section6.section_card
                    .map((element, index) => {
                        return (
                            <Col lg={4} md={6} sm={6} key={index} className="specifications_row">
                                <img className="icon-box specifications_icon" src={element.icon_url} />
                                <div className="specifications_title">{element.title}</div>

                                <HtmlText text={element.description} />

                                {/* <Markup className="specifications_description" content={element.description + "..."} /> */}
                            </Col>
                        )
                    })
                }
            </Row>
        </Container>
    )
}
export default sDesktop;

