import React from 'react';
import { useMediaQuery } from 'react-responsive';
import HandleProjectData from './HandleProjectData';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HandleProjectLists from './HandleProjectLists';
import HandleProjectListsMobile from './HandleProjectListsMobile';
import HandleProjectDataMobile from './HandleProjectDataMobile';

const FeaturedProjects = ({ section3 }) => {
    const isDesktopOrMobile = useMediaQuery({ query: `(max-width: 992px)` });

    const [projectData, setprojectData] = React.useState({});
    const GetProjectData = (data) => {
        setprojectData(data);
    }
    return (
        <div>
            <Container className='section_margin'>
                <Row>
                    <h2 className='header'>{section3.title}</h2>
                </Row>
                <Row>
                    <Col md={12} lg={3} className=''>
                        {!isDesktopOrMobile ?
                            <HandleProjectLists section3={section3} GetProjectData={GetProjectData} />
                            : <HandleProjectListsMobile section3={section3} GetProjectData={GetProjectData} />
                        }
                    </Col>
                    <Col md={12} lg={9}>
                        {!isDesktopOrMobile ? <HandleProjectData projectData={projectData} /> : <HandleProjectDataMobile projectData={projectData} />}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};


export default FeaturedProjects;